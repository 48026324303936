import axios from 'axios';
import { notification } from 'antd';
import { handleErrorNotification } from './utilities';

export const apiGetUsers = async () => {
  try {
    const response = await axios.get('/api/admin/v1/users', {
      params: {},
    });
    if (response.data) {
      return response.data;
    }
    throw new Error('ERROR_UNKNOWN');
  } catch (e) {
    handleErrorNotification(e);
    return [];
  }
};

export const apiGetUserDetails = async (id) => {
  try {
    const response = await axios.get('/api/admin/v1/users/' + id);
    return response.data;
  } catch (e) {
    handleErrorNotification(e);
    return null;
  }
};

export const apiCreateUser = async (params) => {
  try {
    const response = await axios.post('/api/admin/v1/users/', params);
    notification.success({
      message: 'You have created the user successfully',
    });
    return response.data;
  } catch (e) {
    handleErrorNotification(e);
    return null;
  }
};

export const apiPatchUser = async (id, params) => {
  try {
    const response = await axios.patch('/api/admin/v1/users/' + id, params);
    notification.success({
      message: 'You have updated the user successfully',
    });
    return response.data;
  } catch (e) {
    handleErrorNotification(e);
    return null;
  }
};
