import React, { useState } from 'react';
import { Button, Form, Input, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Typo from '../../../components/ui/Typo';
import Card from '../../../components/ui/Card';
import Box from '../../../components/ui/Box';
import { setAuth } from '../../../redux/auth/auth.slice';
import { apiSignIn } from '../../../apis/auth';

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleSubmit = (values) => {
    setLoading(true);
    apiSignIn({
      email: values.email,
      password: values.password,
    })
      .then((result) => {
        if (result) {
          dispatch(
            setAuth({
              ...result.user,
            }),
          );
          notification.success({
            message: 'You have logged in successfully',
          });
          navigate('/dashboard');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box width={400}>
      <Card>
        <Typo variant="h2">Sign In</Typo>
        <Box mt={3}>
          <Form layout="vertical" onFinish={handleSubmit} disabled={loading}>
            <Form.Item
              name="email"
              label="Email"
              required
              rules={[{ required: true, type: 'email' }]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              required
              rules={[{ required: true }]}
            >
              <Input.Password placeholder="Enter your password" />
            </Form.Item>
            <Box mt={4}>
              <Button
                block
                size="large"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Sign In
              </Button>
            </Box>
          </Form>
        </Box>
      </Card>
    </Box>
  );
};

export default LoginPage;
