import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Modal } from 'antd';
import UploadWithCustomRequest from '../../../../components/ui/UploadWithCustomRequest';
import { UploadOutlined } from '@ant-design/icons';

export default function EditDocumentModal({ open, onSubmit, onClose }) {
  const [form] = Form.useForm();

  const handleSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  useEffect(() => {
    form.resetFields();
  }, [open]);

  return (
    <Modal
      open={open}
      title="Upload Document"
      okButtonProps={{
        type: 'primary',
        loading: false,
      }}
      okText="Submit"
      onOk={handleSubmit}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label="Document Name"
          name="name"
          required
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <Input placeholder="Document Name" />
        </Form.Item>
        <Form.Item
          label="File to be uploaded"
          name="file"
          required
          rules={[
            {
              required: true,
              type: 'array',
              min: 1,
            },
          ]}
          valuePropName="fileList"
          getValueFromEvent={(info) => {
            if (Array.isArray(info)) {
              return info;
            }
            return info?.fileList;
          }}
        >
          <UploadWithCustomRequest
            maxCount={1}
            listType="text"
            name="file"
            action="/api/v1/upload"
            folder="trades/requirements"
          >
            <Button type="primary" icon={<UploadOutlined />}>
              Choose a file...
            </Button>
          </UploadWithCustomRequest>
        </Form.Item>
      </Form>
    </Modal>
  );
}

EditDocumentModal.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};
