import React from 'react';
import PropTypes from 'prop-types';
import { Button, Space } from 'antd';

import { Box, Stack, Typo } from '../../../components/ui';

export default function PageHeader({ id, onSubmit, processing }) {
  return (
    <Box>
      <Stack justify="space-between" align="center">
        <Typo variant="h4">
          {id === 'new' ? 'Add New Product' : 'Edit Product'}
        </Typo>
        <Space>
          <Button type="primary" loading={processing} onClick={onSubmit}>
            Submit
          </Button>
        </Space>
      </Stack>
    </Box>
  );
}

PageHeader.propTypes = {
  id: PropTypes.any,
  onSubmit: PropTypes.func,
  processing: PropTypes.bool,
};
