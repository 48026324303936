import colors from './colors';

const theme = {
  space: Array.apply(null, Array(32)).map((_, index) => index * 4),
  colors: {
    primary: colors.primary,
  },
};

export default theme;
