import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Flex, Row } from 'antd';

import { Box } from '../../../components/ui';
import TradeFlowCard from './components/trade-flow.card';
import BusinessInfoCard from './components/business-info.card';
import TradeOverviewCard from './components/trade-overview.card';
import TradeDocumentsCard from './components/trade-documents.card';
import EditDocumentModal from './modals/edit-document.modal';
import TradeShipmentStageCard from './components/trade-shipment-stage.card';
import { EditOutlined } from '@ant-design/icons';
import TradeInitialPaymentsCard from './components/trade-initial-payments.card';

export default function PageTradeInfoTab({
  trade,
  onUploadDocument,
  onDeleteDocument,
}) {
  const [modals, setModals] = useState({});

  const handleOpenModal = (name, data) => {
    setModals((values) => ({
      ...values,
      [name]: data,
    }));
  };

  const handleCloseModal = (name) => {
    setModals((values) => ({
      ...values,
      [name]: null,
    }));
  };

  const handleUploadDocument = (values) => {
    if (values.file[0].response?.filename) {
      handleCloseModal('edit-document');
      onUploadDocument({
        url: values.file[0].response.filename,
        name: values.name,
        ...modals['edit-document'],
      });
    }
  };

  return (
    <Box>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={8} xxl={6}>
          <TradeFlowCard trade={trade} />
          <BusinessInfoCard
            title="Seller Details"
            id={trade?.seller?.company?._id}
            name={trade?.seller?.company?.name}
            logo={trade?.seller?.company?.logo}
            members={trade?.seller?.users}
          />
          <BusinessInfoCard
            title="Buyer Details"
            id={trade?.buyer?.company?._id}
            name={trade?.buyer?.company?.name}
            logo={trade?.buyer?.company?.logo}
            members={trade?.buyer?.users}
          />
        </Col>
        <Col xs={24} sm={24} md={16} xxl={12}>
          <TradeOverviewCard trade={trade} />
          <TradeInitialPaymentsCard trade={trade} />
          {trade?.shipments?.length > 0 ? (
            trade.shipments.map((shipment, index) => (
              <TradeShipmentStageCard
                key={`shipment-${index}`}
                title={`Shipment ${index + 1}`}
                shipment={shipment}
                trade={trade}
                onUploadDocument={(data) =>
                  handleOpenModal('edit-document', data)
                }
              />
            ))
          ) : (
            <Card title="Trade Builder">
              <Flex justify="center" align="center">
                <Button type="primary" icon={<EditOutlined />}>
                  Open Trade Builder
                </Button>
              </Flex>
            </Card>
          )}
        </Col>
        <Col xs={24} sm={24} md={24} xxl={6}>
          <TradeDocumentsCard
            title="Trade Requirements"
            type="trade-requirement"
            trade={trade}
            enableEdit
            enableAdd
            disableMultiple
            onUploadDocument={(data) => handleOpenModal('edit-document', data)}
            onDeleteDocument={onDeleteDocument}
          />
          <TradeDocumentsCard
            title="Sales Purchase Agreement"
            type="sales-agreement"
            trade={trade}
            enableEdit
            enableAdd
            disableMultiple
            onUploadDocument={(data) => handleOpenModal('edit-document', data)}
            onDeleteDocument={onDeleteDocument}
          />
          {trade?.proofOfProductRequired ? (
            <TradeDocumentsCard
              title="Proof of Product"
              type="product-proof"
              trade={trade}
              enableEdit
              enableAdd
              disableMultiple
              onUploadDocument={(data) =>
                handleOpenModal('edit-document', data)
              }
              onDeleteDocument={onDeleteDocument}
            />
          ) : null}
        </Col>
      </Row>

      <EditDocumentModal
        open={modals['edit-document'] ? true : false}
        onSubmit={handleUploadDocument}
        onClose={() => handleCloseModal('edit-document')}
      />
    </Box>
  );
}

PageTradeInfoTab.propTypes = {
  trade: PropTypes.any,
  onUploadDocument: PropTypes.func,
  onDeleteDocument: PropTypes.func,
};
