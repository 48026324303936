import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Select } from 'antd';

import { Box, Typo } from '../../components/ui';

export default function SwitchStatusModal({ open, onConfirm, onCancel }) {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.submit();
  };

  return (
    <Modal
      title="Change Status"
      open={open}
      onOk={handleSubmit}
      onCancel={onCancel}
      okButtonProps={{
        type: 'primary',
      }}
      okText="Confirm"
      cancelText="Cancel"
    >
      <Typo>
        Change the status of the user, disabled users will not be able to log
        into the platform, their products will be hidden from the marketplace
        and existing trades will be put on hold.
      </Typo>
      <Box mt={4}>
        <Form form={form} layout="vertical" onFinish={onConfirm}>
          <Form.Item
            name="status"
            required
            label="Status"
            rules={[
              {
                type: 'string',
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Select placeholder="Select status">
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="disabled">Disabled</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Box>
    </Modal>
  );
}

SwitchStatusModal.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};
