import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import PageBreadcrumbs from './page-breadcrumbs.component';
import PageHeader from './page-header.component';
import PageUsersView from './page-users-view.component';
import UserDetailsView from '../../views/user/user.view';

import { apiGetUsers, apiPatchUser } from '../../apis/users';

export default function UserManagementPage() {
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useQuery(['users'], async () => {
    return await apiGetUsers();
  });
  const [processing, setProcessing] = useState(false);
  const [user, setUser] = useState({
    id: null,
    show: false,
  });

  const handleChangeStatus = async (id, status) => {
    setProcessing(true);
    const result = await apiPatchUser(id, {
      status: status,
    });
    if (result) {
      await refetch();
    }
    setProcessing(false);
  };

  const handleViewProfile = (id) => {
    setUser({
      id: id,
      show: true,
    });
  };

  const handleViewTrades = () => {
    navigate(`/trades`);
  };

  const handleViewTransactions = () => {
    navigate(`/transactions`);
  };

  return (
    <>
      <PageBreadcrumbs />
      <PageHeader />
      <PageUsersView
        data={data ? data : []}
        isLoading={isLoading || processing}
        onChangeStatus={handleChangeStatus}
        onViewProfile={handleViewProfile}
        onViewTrades={handleViewTrades}
        onViewTransactions={handleViewTransactions}
      />
      <UserDetailsView
        id={user.id}
        open={user.show && user.id}
        onClose={() => setUser((values) => ({ ...values, show: false }))}
        onAfterSubmit={refetch}
      />
    </>
  );
}
