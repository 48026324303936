import axios from 'axios';
import { handleErrorNotification } from './utilities';

export const apiUploadFile = async (data) => {
  try {
    const response = await axios.post('/api/v1/upload', data);
    return response.data.data;
  } catch (e) {
    handleErrorNotification(e);
  }
};
