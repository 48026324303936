import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Space, Table, Tag } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import querystring from 'query-string';

import { Box, Typo } from '../../components/ui';
import TextFilterDropdown from '../../components/mixed/TextFilterDropdown';
import UserStatusTag from '../../components/tags/UserStatusTag';
import UserRoleTag from '../../components/tags/UserRoleTag';
import ActionMenu from './action-menu.component';
import SwitchStatusModal from './switch-status.modal';
import { getStoragePath } from '../../apis/utilities';

export default function PageUsersView({
  data,
  isLoading,
  onViewProfile,
  onViewTrades,
  onViewTransactions,
  onChangeStatus,
}) {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleChangeTable = (pagination, filters, sorter) => {
    const params = {
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      ...filters,
      sorter:
        sorter?.order === 'ascend' || sorter?.order === 'descend'
          ? [sorter.field, sorter.order]
          : null,
    };
    const query = querystring.stringify(params, {
      arrayFormat: 'separator',
      skipNull: true,
    });
    navigate(`/users?${query}`);
  };

  const handleOpenSwitchStatusModal = (id) => {
    setOpen(true);
    setSelectedId(id);
  };

  const handleCancelSwitchStatus = () => {
    setOpen(false);
    setSelectedId(null);
  };

  const handleChangeStatus = ({ status }) => {
    handleCancelSwitchStatus();
    onChangeStatus(selectedId, status);
  };

  useEffect(() => {
    const queries = querystring.parse(params.toString(), {
      arrayFormat: 'separator',
    });
    setPagination({
      ...pagination,
      current: queries.pageIndex ? +queries.pageIndex : 1,
      pageSize: queries.pageSize ? +queries.pageSize : 10,
    });
    setFilters({
      name: queries.name ? [queries.name] : null,
      email: queries.email ? [queries.email] : null,
      role: queries.role
        ? typeof queries.role === 'string'
          ? [queries.role]
          : queries.role
        : null,
      status: queries.status
        ? typeof queries.status === 'string'
          ? [queries.status]
          : queries.status
        : null,
    });
    setSorter(
      queries.sorter?.length === 2
        ? {
            field: queries.sorter[0],
            order: queries.sorter[1],
          }
        : {},
    );
  }, [params]);

  return (
    <Box mt={4}>
      <Table
        dataSource={data}
        columns={[
          {
            dataIndex: '_id',
            title: 'ID',
            render: (value) => (
              <Button type="link" onClick={() => onViewProfile(value)}>
                #{value.substr(-6).toUpperCase()}
              </Button>
            ),
          },
          {
            dataIndex: 'company',
            title: 'Business',
            render: (value) => (value?.name ? value.name : ''),
            sorter: (a, b) => (a?.name > b?.name ? 1 : -1),
            sortOrder: sorter.field === 'company' ? sorter.order : null,
          },
          {
            dataIndex: 'name',
            title: 'Name',
            render: (value, row) => (
              <Space>
                <Avatar src={getStoragePath(row.photo)} />
                <Typo>
                  {row.firstName || row.lastName ? (
                    getJoinedName(row)
                  ) : (
                    <Tag>Not specified</Tag>
                  )}
                </Typo>
              </Space>
            ),
            sorter: (a, b) => (a.name > b.name ? 1 : -1),
            sortOrder: sorter.field === 'name' ? sorter.order : null,
            filterDropdown: (props) => (
              <TextFilterDropdown {...props} placeholder="Search name" />
            ),
            onFilter: (value, record) =>
              getJoinedName(record).toLowerCase().includes(value.toLowerCase()),
            filteredValue: filters.name,
          },
          {
            dataIndex: 'email',
            title: 'Email',
            sorter: (a, b) => (a.email > b.email ? 1 : -1),
            sortOrder: sorter.field === 'email' ? sorter.order : null,
            filterDropdown: (props) => (
              <TextFilterDropdown {...props} placeholder="Search email" />
            ),
            onFilter: (value, record) =>
              record.email
                ? record.email.toLowerCase().includes(value.toLowerCase())
                : false,
            filteredValue: filters.email,
          },
          {
            dataIndex: 'role',
            title: 'Role',
            render: (value) => <UserRoleTag role={value} />,
            sorter: (a, b) => (a.role > b.role ? 1 : -1),
            sortOrder: sorter.field === 'role' ? sorter.order : null,
            filters: [
              {
                text: 'Business Owner',
                value: 'owner',
              },
              {
                text: 'Super Admin',
                value: 'superadmin',
              },
              {
                text: 'Admin',
                value: 'admin',
              },
            ],
            filterMode: 'menu',
            onFilter: (value, record) => record.role === value,
            filteredValue: filters.role,
          },
          {
            dataIndex: 'isVerified',
            title: 'KYC',
            render: (value) => (
              <Tag color={value ? 'green' : 'red'}>
                {value ? 'Verified' : 'In Review'}
              </Tag>
            ),
          },
          {
            dataIndex: 'status',
            title: 'Status',
            render: (value) => <UserStatusTag status={value} />,
            sorter: (a, b) => (a.status > b.status ? 1 : -1),
            sortOrder: sorter.field === 'status' ? sorter.order : null,
            filters: [
              {
                text: 'Active',
                value: 'active',
              },
              {
                text: 'Disabled',
                value: 'disabled',
              },
            ],
            filterMode: 'menu',
            onFilter: (value, record) => record.status === value,
            filteredValue: filters.status,
          },
          {
            dataIndex: '_id',
            title: '',
            render: (value) => (
              <ActionMenu
                id={value}
                onChangeStatus={() => handleOpenSwitchStatusModal(value)}
                onViewProfile={onViewProfile}
                onViewTrades={onViewTrades}
                onViewTransactions={onViewTransactions}
              />
            ),
          },
        ]}
        rowKey={(row) => `user-${row._id}`}
        loading={isLoading}
        pagination={pagination}
        onChange={handleChangeTable}
      />
      <SwitchStatusModal
        open={open && selectedId}
        onConfirm={handleChangeStatus}
        onCancel={handleCancelSwitchStatus}
      />
    </Box>
  );
}

function getJoinedName(row) {
  return (
    (row.firstName ? row.firstName : '') +
    ' ' +
    (row.lastName ? row.lastName : '')
  );
}

PageUsersView.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  onChangeStatus: PropTypes.func,
  onViewProfile: PropTypes.func,
  onViewTrades: PropTypes.func,
  onViewTransactions: PropTypes.func,
};
