import React, { useState } from 'react';
import { Avatar, Button, List, Space, Tag } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  FileOutlined,
} from '@ant-design/icons';
import prettyBytes from 'pretty-bytes';

import { Box, Stack, Typo } from '../../components/ui';

import { useCompanyViewModel } from './company.context';
import { apiPatchCompany } from '../../apis/companies';

export default function BusinessDocumentsTab() {
  const { company, onAfterSubmit, reload } = useCompanyViewModel();

  const [accepting, setAccepting] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const handleAccept = async () => {
    setAccepting(true);
    await apiPatchCompany(company?._id, {
      isVerified: true,
    });
    await reload();
    onAfterSubmit();
    setAccepting(false);
  };

  const handleReject = async () => {
    setRejecting(true);
    await apiPatchCompany(company?._id, {
      isVerified: false,
    });
    await reload();
    onAfterSubmit();
    setRejecting(false);
  };

  return (
    <Box>
      <Stack justify="space-between" align="center" mb={3}>
        <Typo variant="h4">Business Documents</Typo>
        <Tag color={company?.isVerified ? 'green' : 'red'}>
          {company?.isVerified ? 'Verified' : 'In Review'}
        </Tag>
      </Stack>
      <List
        dataSource={company?.documents ?? []}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Space key="doc-actions">
                <Button
                  icon={<EyeOutlined />}
                  size="small"
                  href={item.downloadUrl}
                  download={item.filename}
                  target="_blank"
                />
              </Space>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar icon={<FileOutlined />} />}
              title={item.filename}
              description={`Size: ${prettyBytes(item.size)}`}
            />
          </List.Item>
        )}
      />
      <Box mt={4}>
        <Space>
          <Button
            type="primary"
            icon={<CheckCircleOutlined />}
            loading={accepting}
            disabled={rejecting}
            onClick={handleAccept}
          >
            Mark as Verified
          </Button>
          <Button
            type="primary"
            danger
            icon={<CloseCircleOutlined />}
            loading={rejecting}
            disabled={accepting}
            onClick={handleReject}
          >
            Mark as In Review
          </Button>
        </Space>
      </Box>
    </Box>
  );
}
