import React from 'react';
import PropTypes from 'prop-types';
import { Card, Descriptions as AntdDescriptions } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import labels from 'react-phone-number-input/locale/en.json';

import TradeStatusTag from '../../../../components/tags/TradeStatusTag';

const Descriptions = styled(AntdDescriptions)(() => ({
  '& .ant-descriptions-item': {
    paddingBottom: '8px !important',
  },
}));

export default function TradeOverviewCard({ trade }) {
  return (
    <Card
      title="Trade Overview"
      style={{
        marginBottom: 16,
      }}
    >
      <Descriptions
        items={[
          {
            key: 'info-created-at',
            label: 'Requested At',
            children: dayjs(trade?.createdAt).format('DD MMM, YYYY'),
          },
          {
            key: 'info-origin',
            label: 'Origin',
            children: labels[trade?.product?.location],
          },
          {
            key: 'info-destination',
            label: 'Destination',
            children: trade?.port,
          },
          {
            key: 'info-incoterms',
            label: 'Incoterms',
            children: trade?.incoterms,
          },
          {
            key: 'info-total',
            label: 'Value',
            children: '$' + Number(trade?.total).toLocaleString(),
          },
          {
            key: 'info-status',
            label: 'Status',
            children: <TradeStatusTag status={trade?.status} />,
          },
        ]}
      />
    </Card>
  );
}

TradeOverviewCard.propTypes = {
  trade: PropTypes.any,
};
