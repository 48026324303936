import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled('div')(() => ({
  paddingLeft: 24,
  paddingTop: 88,
  paddingRight: 24,
  paddingBottom: 24,
  height: '100vh',
  boxSizing: 'border-box',
  overflowX: 'hidden',
  overflowY: 'auto',
}));

const MainContent = ({ children }) => {
  return <Container>{children}</Container>;
};

MainContent.propTypes = {
  children: PropTypes.any,
};

export default MainContent;
