import axios from 'axios';
import { notification } from 'antd';
import { handleErrorNotification } from './utilities';

export const apiGetTrades = async (params) => {
  try {
    const response = await axios.get('/api/admin/v1/trades', {
      params: params,
    });
    return response.data.data;
  } catch (e) {
    handleErrorNotification(e);
    return [];
  }
};

export const apiGetTradeDetails = async (id) => {
  try {
    const response = await axios.get(`/api/admin/v1/trades/${id}`);
    return response.data;
  } catch (e) {
    handleErrorNotification(e);
    return null;
  }
};

export const apiCreateTradeDocument = async (tradeId, params) => {
  try {
    await axios.post(`/api/admin/v1/trades/${tradeId}/documents`, params);
    notification.success({
      message: 'Uploaded the selected document successfully',
    });
  } catch (e) {
    handleErrorNotification(e);
    return null;
  }
};

export const apiPatchTradeDocument = async ({
  tradeId,
  documentId,
  ...params
}) => {
  try {
    await axios.patch(
      `/api/admin/v1/trades/${tradeId}/documents/${documentId}`,
      params,
    );
    notification.success({
      message: 'Updated the selected document successfully',
    });
  } catch (e) {
    handleErrorNotification(e);
    return null;
  }
};

export const apiDeleteTradeDocument = async (tradeId, documentId) => {
  try {
    await axios.delete(
      `/api/admin/v1/trades/${tradeId}/documents/${documentId}`,
    );
    notification.success({
      message: 'Deleted the selected document successfully',
    });
  } catch (e) {
    handleErrorNotification(e);
    return null;
  }
};
