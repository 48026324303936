import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const pairs = {
  pending: {
    color: 'gray',
    label: 'Pending',
  },
  paying: {
    color: 'blue',
    label: 'Making Payment',
  },
  checking: {
    color: 'orange',
    label: 'Checking Receipt',
  },
  completed: {
    color: 'green',
    label: 'Completed',
  },
};

export default function TransactionStatusTag({ status }) {
  return <Tag color={pairs[status]?.color}>{pairs[status]?.label}</Tag>;
}

TransactionStatusTag.propTypes = {
  status: PropTypes.string,
};
