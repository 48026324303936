import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import { DollarOutlined, SaveOutlined } from '@ant-design/icons';
import ProductsInput from './products-input.component';

export default function EditTransactionModal({
  transaction,
  open,
  onClose,
  onSubmit,
}) {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (transaction) {
      form.setFieldsValue({
        status: transaction.status,
        total: transaction.total,
        fee: transaction.fee,
        products: transaction.products,
      });
    } else {
      form.resetFields();
    }
  }, [transaction]);

  return (
    <Modal
      title="Update Transaction"
      open={open}
      width={900}
      okButtonProps={{
        type: 'primary',
        icon: <SaveOutlined />,
      }}
      okText="Submit"
      onOk={handleSubmit}
      onCancel={onClose}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        style={{
          paddingTop: 16,
        }}
      >
        <Form.Item
          label="Status"
          name="status"
          required
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <Select placeholder="Select status">
            <Select.Option value="checking">Checking Receipt</Select.Option>
            <Select.Option value="completed">Completed</Select.Option>
          </Select>
        </Form.Item>
        <Row gutter={20}>
          <Col xs={12}>
            <Form.Item
              label="Amount"
              name="total"
              required
              rules={[
                {
                  required: true,
                  type: 'number',
                  transform: (value) => Number(value),
                  message: 'This field is required',
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Total Amount"
                prefix={<DollarOutlined />}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="Fee"
              name="fee"
              required
              rules={[
                {
                  required: true,
                  type: 'number',
                  transform: (value) => Number(value),
                  message: 'This field is required',
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Fee Amount"
                prefix={<DollarOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Products"
          name="products"
          required
          rules={[
            {
              required: true,
              type: 'array',
              min: 0,
              message: 'At least one product is required',
            },
          ]}
          valuePropName="items"
          getValueFromEvent={(ev) => ev}
        >
          <ProductsInput />
        </Form.Item>
      </Form>
    </Modal>
  );
}

EditTransactionModal.propTypes = {
  transaction: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
