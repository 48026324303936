import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { Box, Stack, Typo } from '../../../components/ui';

export default function PageHeader({ onAdd }) {
  return (
    <Box>
      <Stack justify="space-between" align="center">
        <Typo variant="h4">Product Management</Typo>
        <Button type="primary" icon={<PlusOutlined />} onClick={onAdd}>
          Add New Product
        </Button>
      </Stack>
    </Box>
  );
}

PageHeader.propTypes = {
  onAdd: PropTypes.func,
};
