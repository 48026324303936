const colors = {
  primary: '#8C794E',
  heading: '#2E2C2F',
  text: '#475B63',
  grey: '#4E4E49',
  gray: '#8C979A',
  light: '#FFFFFF',
  black: '#303030',
  'light.gold': '#EDD29B',
  'royal.blue': '#0E2F44',
  'gray.50': '#FAFAFA',
  'gray.100': '#F4F4F4',
  'gray.200': '#EAEAEA',
  'gray.300': '#D7D7D7',
  'gray.400': '#969696',
  'gray.500': '#667085',
  'gray.600': '#5C5C5C',
  'gray.700': '#374151',
  'gray.800': '#464646',
  'gray.900': '#101828',
  'status.success': '#48A96E',
  'green.highlight': '#ECFFEC',
  'status.warning': '#F5A540',
  'orange.highlight': '#FFF5E8',
  'status.error': '#DC1414',
  'red.highlight': '#FFF2F2',
  'status.info': '#4383FF',
  'blue.highlight': '#E9F1FF',
  'dark.gray': '#949292',
  'light.gray': '#E3E0E0',
  'secondary.200': '#9A9A9A',
  'secondary.300': '#6C6C6C',
  'secondary.500': '#232323',
};

export default colors;
