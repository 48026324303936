import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const pairs = {
  pending: {
    color: 'blue',
    label: 'Pending',
  },
  agreed: {
    color: 'purple',
    label: 'Agreed',
  },
  completed: {
    color: 'green',
    label: 'Completed',
  },
};

export default function DocumentStatusTag({ status }) {
  return <Tag color={pairs[status]?.color}>{pairs[status]?.label}</Tag>;
}

DocumentStatusTag.propTypes = {
  status: PropTypes.string,
};
