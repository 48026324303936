const constants = {
  // API_HOST: 'https://stage.api.oodlmarket.com',
  API_HOST: process.env.REACT_APP_API_HOST
    ? process.env.REACT_APP_API_HOST
    : 'https://dev.api.oodlmarket.com',

  STORAGE_HOST: process.env.REACT_APP_STORAGE_HOST
    ? process.env.REACT_APP_STORAGE_HOST
    : 'https://assets.oodlmarket.com',
};

export default constants;
