import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Space, Table } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import querystring from 'query-string';

import { Box, Typo } from '../../../components/ui';
import TextFilterDropdown from '../../../components/mixed/TextFilterDropdown';
import ActionMenu from './action-menu.component';
import { getStoragePath } from '../../../apis/utilities';
import dayjs from 'dayjs';

export default function PageTradesView({ data, isLoading, companies, onEdit }) {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: data?.count ? data.count : 0,
  });
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});

  const handleChangeTable = (pagination, filters, sorter) => {
    const params = {
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      ...filters,
      sorter:
        sorter?.order === 'ascend' || sorter?.order === 'descend'
          ? [sorter.field, sorter.order]
          : null,
    };
    const query = querystring.stringify(params, {
      arrayFormat: 'separator',
      skipNull: true,
    });
    navigate(`/trades?${query}`);
  };

  useEffect(() => {
    const queries = querystring.parse(params.toString(), {
      arrayFormat: 'separator',
    });
    setPagination({
      ...pagination,
      current: queries.pageIndex ? +queries.pageIndex : 1,
      pageSize: queries.pageSize ? +queries.pageSize : 10,
    });
    setFilters({
      search: queries.search ? [queries.search] : null,
      seller: queries.seller ? [queries.seller] : null,
      buyer: queries.buyer ? [queries.buyer] : null,
      status: queries.status ? [queries.status] : null,
    });
    setSorter(
      queries.sorter?.length === 2
        ? {
            field: queries.sorter[0],
            order: queries.sorter[1],
          }
        : {},
    );
  }, [params]);

  return (
    <Box mt={4}>
      <Table
        dataSource={data?.result ?? []}
        columns={[
          {
            dataIndex: '_id',
            title: 'ID',
            render: (value) => (
              <Button type="link" onClick={() => onEdit(value)}>
                #{value.substr(-6).toUpperCase()}
              </Button>
            ),
          },
          {
            dataIndex: 'seller',
            title: 'Seller',
            render: (value, row) => (
              <Space>
                <Avatar src={getStoragePath(row.seller?.company?.logo)} />
                <Typo>{row.seller?.company?.name}</Typo>
              </Space>
            ),
            filters: companies.map((item) => ({
              text: item.name,
              value: item._id,
            })),
            filterMultiple: false,
            filterSearch: true,
            filteredValue: filters.seller ? filters.seller : null,
          },
          {
            dataIndex: 'buyer',
            title: 'Buyer',
            render: (value, row) => (
              <Space>
                <Avatar src={getStoragePath(row.buyer?.company?.logo)} />
                <Typo>{row.buyer?.company?.name}</Typo>
              </Space>
            ),
            filters: companies.map((item) => ({
              text: item.name,
              value: item._id,
            })),
            filterMultiple: false,
            filterSearch: true,
            filteredValue: filters.buyer ? filters.buyer : null,
          },
          {
            dataIndex: 'search',
            title: 'Product',
            filterDropdown: (props) => (
              <TextFilterDropdown
                {...props}
                placeholder="Search product name"
              />
            ),
            filteredValue: filters.search ? filters.search : null,
            render: (value, row) => (
              <Space>
                <Avatar
                  src={getStoragePath(row.product?.photo)}
                  shape="square"
                />
                <Typo>{row.product?.name}</Typo>
              </Space>
            ),
          },
          {
            dataIndex: 'total',
            title: 'Value',
            render: (value) => '$' + Number(value).toLocaleString(),
            sorter: true,
            sortOrder: sorter.field === 'total' ? sorter.order : null,
          },
          {
            dataIndex: 'status',
            title: 'Status',
            filters: [
              { text: 'New Order', value: 'requested' },
              { text: 'In Agreement', value: 'checking' },
              { text: 'In Payment', value: 'paying' },
              { text: 'In Shipment Stages', value: 'shipment' },
              { text: 'Rejected', value: 'rejected' },
              { text: 'Cancelled', value: 'cancelled' },
              { text: 'Completed', value: 'completed' },
            ],
            filteredValue: filters.status ? filters.status : null,
            filterMultiple: false,
          },
          {
            dataIndex: 'createdAt',
            title: 'Requested At',
            render: (value) => dayjs(value).format('DD MMM YYYY'),
            sorter: true,
            sortOrder: sorter.field === 'createdAt' ? sorter.order : null,
          },
          {
            dataIndex: '_id',
            title: '',
            render: (value) => <ActionMenu id={value} onEdit={onEdit} />,
          },
        ]}
        rowKey={(row) => `business-${row._id}`}
        loading={isLoading}
        pagination={{
          ...pagination,
          total: data?.count ? data.count : 0,
        }}
        onChange={handleChangeTable}
      />
    </Box>
  );
}

PageTradesView.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.bool,
  companies: PropTypes.array,
  onEdit: PropTypes.func,
};
