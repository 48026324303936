import axios from 'axios';
import { notification } from 'antd';
import { handleErrorNotification } from './utilities';

export const apiGetCategories = async () => {
  try {
    const response = await axios.get('/api/admin/v1/categories');
    if (response.data) {
      return response.data;
    }
    throw new Error('ERROR_UNKNOWN');
  } catch (e) {
    handleErrorNotification(e);
    return [];
  }
};

export const apiCreateCategory = async (params) => {
  try {
    const response = await axios.post('/api/admin/v1/categories', params);
    if (response.data) {
      notification.success({
        message: 'You have created the category successfully',
      });
      return response.data;
    }
    throw new Error('ERROR_UNKNOWN');
  } catch (e) {
    handleErrorNotification(e);
    return [];
  }
};

export const apiUpdateCategory = async (id, params) => {
  try {
    const response = await axios.patch(
      `/api/admin/v1/categories/${id}`,
      params,
    );
    if (response.data) {
      notification.success({
        message: 'You have updated the selected category successfully',
      });
      return response.data;
    }
    throw new Error('ERROR_UNKNOWN');
  } catch (e) {
    handleErrorNotification(e);
    return [];
  }
};

export const apiDeleteCategory = async (id) => {
  try {
    await axios.delete(`/api/admin/v1/categories/${id}`);
    notification.success({
      message: 'You have deleted the selected category successfully',
    });
  } catch (e) {
    handleErrorNotification(e);
    return [];
  }
};
