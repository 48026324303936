import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Space, Table } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import querystring from 'query-string';
import dayjs from 'dayjs';

import { Box, Typo } from '../../../components/ui';
import ActionMenu from './action-menu.component';
import TransactionStatusTag from '../../../components/tags/TransactionStatusTag';
import { getStoragePath } from '../../../apis/utilities';

export default function PageTransactionsView({
  data,
  isLoading,
  onViewDetails,
  onViewTrade,
  onViewSeller,
  onViewBuyer,
}) {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});

  const handleChangeTable = (pagination, filters, sorter) => {
    const params = {
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      ...filters,
      sorter:
        sorter?.order === 'ascend' || sorter?.order === 'descend'
          ? [sorter.field, sorter.order]
          : null,
    };
    const query = querystring.stringify(params, {
      arrayFormat: 'separator',
      skipNull: true,
    });
    navigate(`/transactions?${query}`);
  };

  useEffect(() => {
    const queries = querystring.parse(params.toString(), {
      arrayFormat: 'separator',
    });
    setPagination((pagination) => ({
      ...pagination,
      current: queries.pageIndex ? +queries.pageIndex : 1,
      pageSize: queries.pageSize ? +queries.pageSize : 10,
    }));
    setFilters({
      search: queries.search ? [queries.search] : null,
      seller: queries.seller ? [queries.seller] : null,
      buyer: queries.buyer ? [queries.buyer] : null,
      status: queries.status ? [queries.status] : null,
    });
    setSorter(
      queries.sorter?.length === 2
        ? {
            field: queries.sorter[0],
            order: queries.sorter[1],
          }
        : {},
    );
  }, [params]);

  return (
    <Box mt={4}>
      <Table
        dataSource={data?.result ?? []}
        columns={[
          {
            dataIndex: '_id',
            title: 'ID',
            render: (value) => (
              <Button type="link" onClick={() => onViewDetails(value)}>
                #{value.substr(-6).toUpperCase()}
              </Button>
            ),
          },
          {
            dataIndex: 'executer',
            title: 'Executer',
            render: (value, row) => (
              <Space>
                <Avatar src={getStoragePath(row.executer?.photo)} />
                <Typo>
                  {row.executer?.firstName} {row.executer?.lastName}
                </Typo>
              </Space>
            ),
          },
          {
            dataIndex: 'paidAt',
            title: 'Paid At',
            render: (value) =>
              value ? dayjs(value).format('DD/MM/YYYY HH:mm') : '',
            sorter: true,
            sortOrder: sorter.field === 'paidAt' ? sorter.order : null,
          },
          {
            dataIndex: 'trade',
            title: 'Trade ID',
            render: (value) => (
              <Button type="link" onClick={() => onViewTrade(value?._id)}>
                #{value?._id.substr(-6).toUpperCase()}
              </Button>
            ),
          },
          {
            dataIndex: 'total',
            title: 'Amount',
            render: (value) => '$' + Number(value).toLocaleString(),
            sorter: true,
            sortOrder: sorter.field === 'total' ? sorter.order : null,
          },
          {
            dataIndex: 'status',
            title: 'Status',
            filters: [
              { text: 'Checking Receipt', value: 'checking' },
              { text: 'Completed', value: 'completed' },
            ],
            filteredValue: filters.status ? filters.status : null,
            filterMultiple: false,
            render: (value) => <TransactionStatusTag status={value} />,
          },
          {
            dataIndex: '_id',
            title: '',
            render: (value, row) => (
              <ActionMenu
                id={value}
                onViewBuyer={() => onViewBuyer(row.trade?.buyer?.company)}
                onViewSeller={() => onViewSeller(row.trade?.seller?.company)}
                onViewTrade={() => onViewTrade(row.trade?._id)}
                onViewTransaction={() => onViewDetails(value)}
              />
            ),
          },
        ]}
        rowKey={(row) => `business-${row._id}`}
        loading={isLoading}
        pagination={{
          ...pagination,
          total: data?.count ? data.count : 0,
        }}
        onChange={handleChangeTable}
      />
    </Box>
  );
}

PageTransactionsView.propTypes = {
  data: PropTypes.any,
  count: PropTypes.number,
  isLoading: PropTypes.bool,
  onViewSeller: PropTypes.func,
  onViewBuyer: PropTypes.func,
  onViewTrade: PropTypes.func,
  onViewDetails: PropTypes.func,
};
