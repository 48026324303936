import React, { useState } from 'react';
import { useQuery } from 'react-query';

import PageBreadcrumbs from './page-breadcrumbs.component';
import PageHeader from './page-header.component';
import PageCompaniesView from './page-companies-view.component';
import CompanyDetailsView from '../../views/company/company.view';

import { apiGetCompanies } from '../../apis/companies';

export default function BusinessManagementPage() {
  const { data, isLoading, refetch } = useQuery(['companies'], async () => {
    return await apiGetCompanies();
  });
  const [company, setCompany] = useState({
    id: null,
    show: false,
  });

  const handleEdit = (id) => {
    setCompany({
      id: id,
      show: true,
    });
  };

  return (
    <>
      <PageBreadcrumbs />
      <PageHeader />
      <PageCompaniesView
        data={data ? data : []}
        isLoading={isLoading}
        onEdit={handleEdit}
      />
      <CompanyDetailsView
        id={company.id}
        open={company.show}
        onClose={() => setCompany((values) => ({ ...values, show: false }))}
        onAfterSubmit={refetch}
      />
    </>
  );
}
