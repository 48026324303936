import React from 'react';
import { Outlet } from 'react-router-dom';

import Stack from '../../ui/Stack';

export default function AuthLayout() {
  return (
    <Stack justify="center" align="center" width={1} height="100vh">
      <Outlet />
    </Stack>
  );
}
