import axios from 'axios';
import { handleErrorNotification } from './utilities';

export const apiSignIn = async ({ email, password }) => {
  try {
    const response = await axios.post('/api/admin/v1/auth/signin', {
      email: email,
      password: password,
    });

    if (response.data) {
      return response.data;
    }
    throw new Error('ERROR_UNKNOWN');
  } catch (e) {
    handleErrorNotification(e);
    return null;
  }
};

export const apiGetProfile = async () => {
  try {
    const response = await axios.get('/api/v1/profile');

    if (response.data) {
      return response.data;
    }
    throw new Error('ERROR_UNKNOWN');
  } catch (e) {
    handleErrorNotification(e);
    return null;
  }
};
