import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { apiGetProfile } from '../apis/auth';
import { updateAuth } from '../redux/auth/auth.slice';

export default function AuthGuard({ children }) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.auth.id && state.auth.accessToken,
  );

  useEffect(() => {
    apiGetProfile().then((result) => {
      dispatch(
        updateAuth({
          photo: result?.user?.photo ? result.user.photo : null,
          name:
            (result?.user?.firstName ?? '') +
            ' ' +
            (result?.user?.lastName ?? ''),
        }),
      );
    });
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/auth/login" />;
}

AuthGuard.propTypes = {
  children: PropTypes.element,
};
