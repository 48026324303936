import React from 'react';
import PropTypes from 'prop-types';
import { Card, Table } from 'antd';

export default function PageItemsCard({ transaction }) {
  return (
    <Card
      title="Products"
      style={{
        marginBottom: 16,
      }}
    >
      <Table
        dataSource={transaction?.products ?? []}
        columns={[
          {
            dataIndex: 'name',
            title: 'Product',
          },
          {
            dataIndex: 'quantity',
            title: 'Qty',
            render: (value) => Number(value).toLocaleString(),
          },
          {
            dataIndex: 'price',
            title: 'Unit Price',
            render: (value) => '$' + Number(value).toLocaleString(),
          },
          {
            dataIndex: 'tax',
            title: 'Tax',
            render: (value) => value + '%',
          },
          {
            dataIndex: 'total',
            title: 'Total Amount',
            render: (value) => '$' + Number(value).toLocaleString(),
          },
        ]}
        pagination={{
          position: ['none', 'none'],
        }}
        rowKey={(row) => row._id}
      />
    </Card>
  );
}

PageItemsCard.propTypes = {
  transaction: PropTypes.array,
};
