import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Image, Layout, Menu as AntMenu, Drawer } from 'antd';
import {
  BuildOutlined,
  ClockCircleOutlined,
  DashboardOutlined,
  HolderOutlined,
  MessageOutlined,
  NotificationOutlined,
  SettingOutlined,
  TagOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import Stack from '../../ui/Stack';
import Box from '../../ui/Box';
import colors from '../../../config/colors';
import imgWhiteLogo from '../../../assets/logos/logo-white.png';

const Sider = styled(Layout.Sider)(() => ({
  '&.ant-layout-sider': {
    background: `${colors['royal.blue']} !important`,
    height: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
}));

const Menu = styled(AntMenu)(() => ({
  '&.ant-menu-root': {
    background: 'transparent',
  },
  '& .ant-menu-item': {
    color: 'white !important',
    fontWeight: 400,
    borderLeft: '6px solid transparent',
    paddingLeft: '18px !important',
    margin: 0,
    marginBlock: '0 !important',
  },
  '& .ant-menu-item.ant-menu-item-selected': {
    color: 'white',
    fontWeight: 600,
    borderLeft: '6px solid white',
    background:
      'linear-gradient(89.89deg, #8C794E 57.58%, rgba(137, 182, 145, 0) 99.9%)',
  },
  '& .ant-menu-submenu .ant-menu-submenu-title': {
    color: 'white !important',
  },
  '& .ant-menu-submenu .ant-menu-item': {
    paddingLeft: '38px !important',
  },
}));

const MainSideBar = ({ open, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMd = useMediaQuery({
    query: '(max-width: 992px)',
  });

  const [selectedKeys, setSelectedKeys] = useState([]);

  const handleNavigate = (path) => {
    return () => {
      navigate(path);
    };
  };

  const items = useMemo(
    () => [
      {
        key: 'key-dashboard',
        icon: <DashboardOutlined />,
        label: 'Dashboard',
        path: '/dashboard',
        onClick: handleNavigate('/dashboard'),
      },
      {
        key: 'key-users',
        icon: <UserOutlined />,
        label: 'Users',
        path: '/users',
        onClick: handleNavigate('/users'),
      },
      {
        key: 'key-businesses',
        icon: <BuildOutlined />,
        label: 'Businesses',
        path: '/businesses',
        onClick: handleNavigate('/businesses'),
      },
      {
        key: 'key-contents',
        icon: <TagOutlined />,
        label: 'Content',
        children: [
          {
            key: 'key-categories',
            icon: <HolderOutlined />,
            label: 'Categories',
            path: '/categories',
            onClick: handleNavigate('/categories'),
          },
          {
            key: 'key-products',
            icon: <HolderOutlined />,
            label: 'Products',
            path: '/products',
            onClick: handleNavigate('/products'),
          },
        ],
      },
      {
        key: 'key-trades',
        icon: <MessageOutlined />,
        label: 'Trades',
        path: '/trades',
        onClick: handleNavigate('/trades?sorter=createdAt,descend'),
      },
      {
        key: 'key-transactions',
        icon: <ClockCircleOutlined />,
        label: 'Transactions',
        path: '/transactions',
        onClick: handleNavigate('/transactions'),
      },
      {
        key: 'key-tickets',
        icon: <MessageOutlined />,
        label: 'Support Tickets',
        path: '/tickets',
        onClick: handleNavigate('/tickets'),
      },
      {
        key: 'key-reports',
        icon: <SettingOutlined />,
        label: 'Reports',
        path: '/reports',
        onClick: handleNavigate('/reports'),
      },
      {
        key: 'key-notifications',
        icon: <NotificationOutlined />,
        label: 'Notifications',
        path: '/notifications',
        onClick: handleNavigate('/notifications'),
      },
      {
        key: 11,
        icon: <SettingOutlined />,
        label: 'Settings',
      },
    ],
    [],
  );

  useEffect(() => {
    if (!isMd) {
      onClose();
    }
  }, [isMd]);

  useEffect(() => {
    const newKeys = [];
    for (const item of items) {
      if (item.children) {
        for (const subitem of item.children) {
          if (location.pathname.includes(subitem.path)) {
            newKeys.push(item.key);
            newKeys.push(subitem.key);
          }
        }
      } else {
        if (location.pathname.includes(item.path)) {
          newKeys.push(item.key);
          break;
        }
      }
    }
    setSelectedKeys(newKeys);
  }, [location, items]);

  return (
    <>
      <Sider breakpoint="lg" width={272} collapsedWidth={0}>
        <Stack py={8} justify="center">
          <Image src={imgWhiteLogo} preview={false} width="80%" />
        </Stack>
        <Box mt={1}>
          <Menu
            mode="inline"
            selectedKeys={selectedKeys}
            items={items}
            openKeys={isMd ? undefined : ['key-contents']}
            expandIcon={<></>}
          />
        </Box>
      </Sider>
      {isMd && (
        <Drawer
          open={open}
          onClose={onClose}
          placement="left"
          closable={false}
          styles={{
            body: {
              padding: 0,
            },
          }}
          width={272}
        >
          <Sider width={272}>
            <Stack py={8} justify="center">
              <Image src={imgWhiteLogo} preview={false} />
            </Stack>
            <Box mt={1}>
              <Menu
                mode="inline"
                selectedKeys={selectedKeys}
                items={items}
                openKeys={['key-contents']}
                expandIcon={<></>}
              />
            </Box>
          </Sider>
        </Drawer>
      )}
    </>
  );
};

MainSideBar.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MainSideBar;
