import React, { useState } from 'react';
import { Spin, Tabs } from 'antd';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import PageBreadcrumbs from './page-breadcrumbs.component';
import PageHeader from './page-header.component';

import PageTradeInfoTab from './page-trade-info.tab';

import {
  apiCreateTradeDocument,
  apiDeleteTradeDocument,
  apiGetTradeDetails,
  apiPatchTradeDocument,
} from '../../../apis/trades';
import PageDocumentsTab from './page-documents.tab';

export default function EditTradePage() {
  const { id } = useParams();

  const {
    data: trade,
    isLoading,
    refetch,
  } = useQuery(['trades', id], async () => {
    return await apiGetTradeDetails(id);
  });
  const [processing, setProcessing] = useState(false);

  const handleUploadDocument = async (values) => {
    setProcessing(true);
    if (values.documentId) {
      await apiPatchTradeDocument({
        tradeId: id,
        ...values,
      });
    } else {
      delete values.documentId;
      await apiCreateTradeDocument(id, values);
    }
    await refetch();
    setProcessing(false);
  };

  const handleDeleteDocument = async (documentId) => {
    setProcessing(true);
    await apiDeleteTradeDocument(id, documentId);
    await refetch();
    setProcessing(false);
  };

  return (
    <>
      <PageBreadcrumbs />
      <PageHeader />
      <Spin spinning={isLoading || processing}>
        <Tabs
          items={[
            {
              key: 'tab-information',
              label: 'Trade Information',
              children: (
                <PageTradeInfoTab
                  trade={trade}
                  onUploadDocument={handleUploadDocument}
                  onDeleteDocument={handleDeleteDocument}
                />
              ),
            },
            {
              key: 'tab-documents',
              label: 'Documents',
              children: <PageDocumentsTab trade={trade} />,
            },
          ]}
          type="card"
        />
      </Spin>
    </>
  );
}
