import React from 'react';

import { Box, Typo } from '../../../components/ui';

export default function PageHeader() {
  return (
    <Box>
      <Typo variant="h4">Trade Management</Typo>
    </Box>
  );
}
