import React, { useEffect, useState } from 'react';
import { Form, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import PageBreadcrumbs from './page-breadcrumbs.component';
import PageHeader from './page-header.component';
import PageMainForm from './page-main-form.component';

import {
  apiCreateProduct,
  apiGetProductDetails,
  apiUpdateProduct,
} from '../../../apis/products';
import { apiGetCategories } from '../../../apis/categories';
import { apiGetCompanies } from '../../../apis/companies';
import { getFilenameFromUrl, getStoragePath } from '../../../apis/utilities';

export default function EditProductPage() {
  const navigate = useNavigate();
  const params = useParams();
  const { data: companies } = useQuery(['companies'], apiGetCompanies);
  const { data: categories } = useQuery(['categories'], apiGetCategories);
  const {
    data,
    isLoading,
    refetch: reload,
  } = useQuery(
    ['products', params.id],
    async () => {
      if (params.id === 'new') {
        return null;
      } else {
        return await apiGetProductDetails(params.id);
      }
    },
    {
      enabled: !!companies && !!categories,
    },
  );

  const [form] = Form.useForm();
  const [formKey, setFormKey] = useState(Date.now());
  const [processing, setProcessing] = useState(false);

  const handleSave = () => {
    form.submit();
  };

  const handleSubmit = async ({ id, ...values }) => {
    if (!values.videos[0].response?.filename) {
      notification.error({
        message: 'Please upload product video',
      });
      return;
    }

    const params = {
      ...values,
      photos: values.photos
        .filter((item) => item.status === 'done' && item.response?.filename)
        .map((item) => item.response.filename),
      video: values.videos[0].response.filename,
      attachments: values.attachments
        .filter((item) => item.status === 'done' && item.response?.filename)
        .map((item) => item.response.filename),
      specifications: values.specifications.filter(
        (item) => item.specification && item.value,
      ),
    };
    delete params.videos;

    if (params.specifications.length < 1) {
      notification.error({
        message: 'Please set product specifications correctly',
      });
      return;
    }

    setProcessing(true);
    if (id) {
      await apiUpdateProduct(id, params);
      await reload();
    } else {
      await apiCreateProduct(params);
      navigate(-1);
    }
    setProcessing(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      id: data?._id ? data._id : null,
      company: data?.company ? data.company._id : null,
      name: data?.name ? data.name : '',
      category: data?.category ? data.category._id : null,
      location: data?.location ? data.location : '',
      incoterms: data?.incoterms ? data.incoterms : [],
      description: data?.description ? data.description : '',
      specifications: data?.specifications ? data.specifications : [],
      photos: data?.photos
        ? data.photos.map((item, index) => ({
            uid: -index,
            name: getFilenameFromUrl(item),
            url: getStoragePath(item),
            status: 'done',
            response: {
              filename: item,
            },
          }))
        : [],
      videos: data?.video
        ? [
            {
              uid: -1,
              name: getFilenameFromUrl(data.video),
              url: getFilenameFromUrl(data.video),
              status: 'done',
              response: {
                filename: data.video,
              },
            },
          ]
        : [],
      attachments: data?.attachments
        ? data.attachments.map((item, index) => ({
            uid: -index,
            name: getFilenameFromUrl(item),
            url: getStoragePath(item),
            status: 'done',
            response: {
              filename: item,
            },
          }))
        : [],
      price: data?.price ? data.price : 0,
      unit: data?.unit ? data.unit : '',
      minimumOrderQuantity: data?.minimumOrderQuantity
        ? data.minimumOrderQuantity
        : 0,
    });

    setFormKey(Date.now());
  }, [data]);

  return (
    <>
      <PageBreadcrumbs id={params.id} />
      <PageHeader
        id={params.id}
        processing={processing}
        onSubmit={handleSave}
      />
      <PageMainForm
        key={formKey}
        formInstance={form}
        loading={isLoading}
        processing={!!processing}
        companies={companies ? companies : []}
        categories={categories ? categories : []}
        onSubmit={handleSubmit}
      />
    </>
  );
}
