import colors from './colors';

const theme = {
  token: {
    colorPrimary: colors.primary,
    colorSuccess: colors['status.success'],
    borderRadius: 4,
    fontFamily: 'Inter',
  },
  components: {
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0,
    },
  },
};

export default theme;
