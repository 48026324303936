import axios from 'axios';
import { notification } from 'antd';
import { handleErrorNotification } from './utilities';

export const apiGetProducts = async () => {
  try {
    const response = await axios.get('/api/admin/v1/products');
    if (response.data) {
      return response.data;
    }
    throw new Error('ERROR_UNKNOWN');
  } catch (e) {
    handleErrorNotification(e);
    return [];
  }
};

export const apiGetProductDetails = async (id) => {
  try {
    const response = await axios.get(`/api/admin/v1/products/${id}`);
    if (response.data) {
      return response.data;
    }
    throw new Error('ERROR_UNKNOWN');
  } catch (e) {
    handleErrorNotification(e);
    return [];
  }
};

export const apiCreateProduct = async (data) => {
  try {
    const response = await axios.post('/api/admin/v1/products', data);
    if (response.data) {
      notification.success({
        message: 'You have created the product successfully',
      });
      return response.data;
    }
    throw new Error('ERROR_UNKNOWN');
  } catch (e) {
    handleErrorNotification(e);
    return null;
  }
};

export const apiUpdateProduct = async (id, data) => {
  try {
    const response = await axios.patch(`/api/admin/v1/products/${id}`, data);
    if (response.data) {
      notification.success({
        message: 'You have updated the product successfully',
      });
      return response.data;
    }
    throw new Error('ERROR_UNKNOWN');
  } catch (e) {
    handleErrorNotification(e);
    return null;
  }
};

export const apiUpdateProductStatus = async (id, status) => {
  try {
    const response = await axios.patch(`/api/admin/v1/products/${id}`, {
      status: status,
    });
    if (response.data) {
      notification.success({
        message: 'You have updated the product successfully',
      });
      return response.data;
    }
    throw new Error('ERROR_UNKNOWN');
  } catch (e) {
    handleErrorNotification(e);
    return null;
  }
};
