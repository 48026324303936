import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'antd';

import { Box, Stack } from '../ui';

export default function TextFilterDropdown({
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
  placeholder,
}) {
  return (
    <Box p={2}>
      <Input
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={(ev) =>
          setSelectedKeys(
            ev.currentTarget.value ? [ev.currentTarget.value] : [],
          )
        }
        onPressEnter={() => confirm({ closeDropdown: true })}
        style={{ display: 'block' }}
      />
      <Stack justify="space-between" mt={2}>
        <Button
          size="small"
          type="text"
          onClick={() => {
            clearFilters();
          }}
        >
          Reset
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={() => confirm({ closeDropdown: true })}
        >
          Search
        </Button>
      </Stack>
    </Box>
  );
}

TextFilterDropdown.propTypes = {
  selectedKeys: PropTypes.any,
  setSelectedKeys: PropTypes.any,
  confirm: PropTypes.any,
  clearFilters: PropTypes.any,
  placeholder: PropTypes.string,
};
