import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Spin, Tabs } from 'antd';
import {
  FileOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useQuery } from 'react-query';

import { CompanyViewContext } from './company.context';
import BusinessDetailsTab from './details.tab';
import BusinessMembersTab from './members.tab';
import BusinessDocumentsTab from './documents.tab';

import { apiGetCompanyDetails } from '../../apis/companies';

export default function CompanyDetailsView({
  id,
  open,
  onClose,
  onAfterSubmit,
}) {
  const { data, isLoading, refetch } = useQuery(
    ['businesses', id],
    async () => {
      if (id) {
        return await apiGetCompanyDetails(id);
      } else {
        return null;
      }
    },
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="right"
      title="Company Details"
      width={800}
    >
      <CompanyViewContext.Provider
        value={{
          company: data,
          reload: refetch,
          onAfterSubmit,
        }}
      >
        <Spin spinning={isLoading}>
          <Tabs
            items={[
              {
                key: 'tab-business',
                label: 'Details',
                icon: <InfoCircleOutlined />,
                children: <BusinessDetailsTab />,
              },
              {
                key: 'tab-members',
                label: 'Members',
                icon: <UserOutlined />,
                children: <BusinessMembersTab />,
              },
              {
                key: 'tab-documents',
                label: 'Documents',
                icon: <FileOutlined />,
                children: <BusinessDocumentsTab />,
              },
            ]}
            tabPosition="left"
          />
        </Spin>
      </CompanyViewContext.Provider>
    </Drawer>
  );
}

CompanyDetailsView.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAfterSubmit: PropTypes.func,
};
