import React from 'react';

import { Box, Typo } from '../../../components/ui';

export default function PageHeader() {
  return (
    <Box mb={3}>
      <Typo variant="h4">Trade Details</Typo>
    </Box>
  );
}
