import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import PageBreadcrumbs from './page-breadcrumbs.component';
import PageHeader from './page-header.component';
import PageProductsView from './page-products-view.component';
import ProductSwitchStatusModal from './switch-status.modal';

import { apiGetProducts, apiUpdateProductStatus } from '../../../apis/products';

export default function ProductManagementPage() {
  const navigate = useNavigate();

  const {
    data: products,
    isLoading,
    refetch: reload,
  } = useQuery(['products'], async () => {
    return await apiGetProducts();
  });
  const [processing, setProcessing] = useState(false);
  const [switchRow, setSwitchRow] = useState(null);

  const handleAdd = useCallback(() => {
    navigate('/products/new');
  }, [navigate]);

  const handleApprove = useCallback(async (id) => {
    setProcessing(true);
    await apiUpdateProductStatus(id, 'Published');
    await reload();
    setProcessing(false);
  }, []);

  const handleEdit = useCallback(
    (id) => {
      navigate(`/products/${id}`);
    },
    [navigate],
  );

  const handleChangeStatus = useCallback(
    (row) => {
      setSwitchRow({
        id: row._id,
        status: row.status,
      });
    },
    [setSwitchRow],
  );

  const handleArchive = useCallback(async (id) => {
    setProcessing(true);
    await apiUpdateProductStatus(id, 'Archived');
    await reload();
    setProcessing(false);
  }, []);

  const handleViewSeller = useCallback(
    (id) => {
      navigate(`/companies/${id}`);
    },
    [navigate],
  );

  const handleSubmitChangeStatus = useCallback(async (values) => {
    if (values.id) {
      setProcessing(true);
      await apiUpdateProductStatus(values.id, values.status);
      await reload();
      setProcessing(false);
      setSwitchRow(null);
    }
  }, []);

  return (
    <>
      <PageBreadcrumbs />
      <PageHeader onAdd={handleAdd} />
      <PageProductsView
        data={products ? products : []}
        isLoading={isLoading || processing}
        onApprove={handleApprove}
        onEdit={handleEdit}
        onChangeStatus={handleChangeStatus}
        onArchive={handleArchive}
        onViewSeller={handleViewSeller}
      />
      <ProductSwitchStatusModal
        open={!!switchRow}
        processing={processing}
        initialValues={switchRow}
        onSubmit={handleSubmitChangeStatus}
        onCancel={() => setSwitchRow(null)}
      />
    </>
  );
}
