import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Flex } from 'antd';
import DocumentItem from './document-item.component';
import { PlusOutlined } from '@ant-design/icons';

export default function TradeDocumentsCard({
  trade,
  title,
  type,
  enableAdd,
  enableEdit,
  enableDelete,
  disableMultiple,
  onUploadDocument,
  onDeleteDocument,
}) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (trade?.documents && trade.documents.length > 0) {
      setItems(trade.documents.filter((item) => item.type === type));
    } else {
      setItems([]);
    }
  }, [trade, type]);

  return (
    <Card
      title={title}
      style={{
        marginBottom: 16,
      }}
    >
      <Flex vertical gap={12}>
        {items.map((item, index) => (
          <DocumentItem
            key={`doc-${type}-${index}`}
            name={item.name}
            path={item.url}
            status={item.status}
            onEdit={() =>
              onUploadDocument({
                documentId: item._id,
                type: type,
              })
            }
            onDelete={() => onDeleteDocument(item._id)}
            enableEdit={enableEdit}
            enableDelete={enableDelete}
          />
        ))}
      </Flex>
      {enableAdd &&
      (!disableMultiple || (disableMultiple && items.length < 1)) ? (
        <Button
          type="default"
          icon={<PlusOutlined />}
          size="small"
          style={{
            marginTop: 16,
          }}
          onClick={() =>
            onUploadDocument({
              documentId: null,
              name: title,
              type: type,
            })
          }
        >
          Add Document
        </Button>
      ) : null}
    </Card>
  );
}

TradeDocumentsCard.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  trade: PropTypes.any,
  enableAdd: PropTypes.bool,
  enableEdit: PropTypes.bool,
  enableDelete: PropTypes.bool,
  disableMultiple: PropTypes.bool,
  onUploadDocument: PropTypes.func,
  onDeleteDocument: PropTypes.func,
};
