import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'antd';
import {
  EyeOutlined,
  FileOutlined,
  MoreOutlined,
  SwapOutlined,
  WalletOutlined,
} from '@ant-design/icons';

export default function ActionMenu({
  id,
  onChangeStatus,
  onViewProfile,
  onViewTrades,
  onViewTransactions,
}) {
  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              label: 'View Profile',
              icon: <EyeOutlined />,
              onClick: () => onViewProfile(id),
            },
            {
              label: 'Change Status',
              icon: <SwapOutlined />,
              onClick: () => onChangeStatus(id),
            },
            {
              label: 'View Trades',
              icon: <FileOutlined />,
              onClick: () => onViewTrades(id),
            },
            {
              label: 'View Transactions',
              icon: <WalletOutlined />,
              onClick: () => onViewTransactions(id),
            },
          ],
        }}
        trigger={['click']}
      >
        <Button icon={<MoreOutlined />} size="small" />
      </Dropdown>
    </>
  );
}

ActionMenu.propTypes = {
  id: PropTypes.any,
  onChangeStatus: PropTypes.func,
  onViewProfile: PropTypes.func,
  onViewTrades: PropTypes.func,
  onViewTransactions: PropTypes.func,
};
