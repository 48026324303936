import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Space, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

import DocumentStatusTag from '../../../../components/tags/DocumentStatusTag';
import {
  getStoragePath,
  getFilenameFromUrl,
  getFileTypeFromUrl,
} from '../../../../apis/utilities';

export default function DocumentItem({
  name,
  path,
  status,
  enableEdit,
  enableDelete,
  onEdit,
  onDelete,
}) {
  const [type, setType] = useState('');

  const handleOpen = useCallback(() => {
    window.open(getStoragePath(path), '_blank');
  }, [path]);

  useEffect(() => {
    const result = getFileTypeFromUrl(path);
    setType(result);
  }, [type]);

  return (
    <Flex justify="space-between" align="center">
      <Space>
        <Space direction="vertical" size={0}>
          <Typography.Text type="secondary">
            {name} <DocumentStatusTag status={status} />
          </Typography.Text>
          <Typography.Text>{getFilenameFromUrl(path)}</Typography.Text>
        </Space>
      </Space>
      <Space>
        <Button icon={<EyeOutlined />} size="small" onClick={handleOpen} />
        {enableEdit && (
          <Button
            icon={<EditOutlined />}
            size="small"
            disabled={status !== 'pending'}
            onClick={onEdit}
          />
        )}
        {enableDelete && (
          <Button
            icon={<DeleteOutlined />}
            size="small"
            disabled={status !== 'pending'}
            onClick={onDelete}
          />
        )}
      </Space>
    </Flex>
  );
}

DocumentItem.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string,
  status: PropTypes.string,
  enableEdit: PropTypes.bool,
  enableDelete: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};
