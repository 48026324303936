import axios from 'axios';
import { notification } from 'antd';
import { handleErrorNotification } from './utilities';

export const apiGetCompanies = async () => {
  try {
    const response = await axios.get('/api/admin/v1/companies');
    if (response.data) {
      return response.data;
    }
    throw new Error('ERROR_UNKNOWN');
  } catch (e) {
    handleErrorNotification(e);
    return [];
  }
};

export const apiGetCompanyDetails = async (id) => {
  try {
    const response = await axios.get('/api/admin/v1/companies/' + id);
    return response.data;
  } catch (e) {
    handleErrorNotification(e);
    return null;
  }
};

export const apiCreateCompany = async (params) => {
  try {
    const response = await axios.post('/api/admin/v1/companies/', params);
    notification.success({
      message: 'You have created the business successfully',
    });
    return response.data;
  } catch (e) {
    handleErrorNotification(e);
    return null;
  }
};

export const apiPatchCompany = async (id, params) => {
  try {
    const response = await axios.patch('/api/admin/v1/companies/' + id, params);
    notification.success({
      message: 'You have updated the business successfully',
    });
    return response.data;
  } catch (e) {
    handleErrorNotification(e);
    return null;
  }
};
