import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';

import { apiUploadFile } from '../../apis/upload';

export default function UploadWithCustomRequest({
  children,
  folder,
  ...props
}) {
  const handleCustomRequest = useCallback(
    (options) => {
      const data = new FormData();
      data.append('files', options.file);
      data.append('key', folder);

      apiUploadFile(data)
        .then((result) => {
          if (result) {
            options.onProgress({
              percent: 100,
            });
            options.onSuccess({
              filename: result,
            });
          } else {
            options.onError(new Error('ERROR_UNKNOWN'));
          }
        })
        .catch((e) => {
          options.onError(e);
        });
    },
    [folder],
  );

  return (
    <Upload
      action="/api/v1/upload"
      customRequest={handleCustomRequest}
      {...props}
    >
      {children}
    </Upload>
  );
}

UploadWithCustomRequest.propTypes = {
  children: PropTypes.node,
  folder: PropTypes.string,
};
