import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Tag } from 'antd';
import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { getCountries, formatPhoneNumberIntl } from 'react-phone-number-input';
import { City, State } from 'country-state-city';
import labels from 'react-phone-number-input/locale/en';

import { Box, Stack, Typo, UploadWithCropper } from '../../components/ui';

import { useCompanyViewModel } from './company.context';
import { getFilenameFromUrl, getStoragePath } from '../../apis/utilities';
import { apiPatchCompany } from '../../apis/companies';

export default function BusinessDetailsTab() {
  const { company, onAfterSubmit } = useCompanyViewModel();

  const [form] = Form.useForm();
  const countries = useMemo(() => {
    return getCountries().map((item) => ({
      code: item,
      label: labels[item],
    }));
  }, []);

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    form.submit();
  };

  const handleFinish = async (values) => {
    const data = {
      ...values,
      logo:
        values.logo.length > 0 && values.logo[0].response?.filename
          ? values.logo[0].response.filename
          : '',
    };
    delete data.email;
    delete data.phone;

    setLoading(true);
    await apiPatchCompany(data.id, data);
    setLoading(false);

    if (onAfterSubmit) {
      onAfterSubmit();
    }
  };

  useEffect(() => {
    if (company) {
      form.setFieldsValue({
        id: company._id,
        email: company.email,
        phone: company.phone ? formatPhoneNumberIntl('+' + company.phone) : '',
        name: company.name,
        country: company.country,
        state: company.state,
        city: company.city,
        address: company.address,
        zipCode: company.zipCode,
        tradingName: company.tradingName,
        regNumber: company.regNumber,
        logo: company.logo
          ? [
              {
                uid: Date.now(),
                name: getFilenameFromUrl(company.logo),
                url: getStoragePath(company.logo),
                status: 'done',
                response: {
                  filename: company.logo,
                },
              },
            ]
          : [],
      });
    } else {
      form.resetFields();
    }
  }, [company]);

  return (
    <Box>
      <Stack justify="space-between" align="center" mb={3}>
        <Typo variant="h4">Business Details</Typo>
        <Tag color={company?.isVerified ? 'green' : 'red'}>
          {company?.isVerified ? 'Verified' : 'In Review'}
        </Tag>
      </Stack>
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Row>
          <Col xs={24} md={24}>
            <Form.Item
              label="Logo"
              name="logo"
              valuePropName="files"
              getValueFromEvent={(info) => {
                if (Array.isArray(info)) {
                  return info;
                }
                return info?.fileList;
              }}
            >
              <UploadWithCropper
                cropProps={{
                  aspect: 1 / 1,
                  quality: 1,
                }}
                uploadProps={{
                  listType: 'picture-card',
                  name: 'files',
                  accept: 'image/*',
                  multiple: true,
                  maxCount: 1,
                }}
                folder="logos"
              >
                <div>
                  <UploadOutlined />
                  <Typo>Upload</Typo>
                </div>
              </UploadWithCropper>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item label="Email Address" name="email" required>
              <Input placeholder="Email Address" readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Phone Number" name="phone" required>
              <Input placeholder="Phone Number" readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item
              label="Trading Name"
              name="tradingName"
              required
              rules={[
                {
                  required: true,
                  message: 'This field can not be empty',
                },
              ]}
            >
              <Input placeholder="Trading Name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Registration Number"
              name="regNumber"
              required
              rules={[
                {
                  required: true,
                  message: 'This field can not be empty',
                },
              ]}
            >
              <Input placeholder="Business Registration Number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item
              label="Name"
              name="name"
              required
              rules={[
                {
                  required: true,
                  message: 'This field can not be empty',
                },
              ]}
            >
              <Input placeholder="Business Name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Address"
              name="address"
              required
              rules={[
                {
                  required: true,
                  message: 'This field can not be empty',
                },
              ]}
            >
              <Input placeholder="Address" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item
              label="Country"
              name="country"
              required
              rules={[
                {
                  required: true,
                  message: 'This field can not be empty',
                },
              ]}
            >
              <Select
                placeholder="Select country"
                showSearch
                filterOption={(input, option) => {
                  return option?.children
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {countries.map((item, index) => (
                  <Select.Option key={`country-${index}`} value={item.code}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="State" name="state">
              <Select
                placeholder="Select state"
                options={State.getAllStates().map((item) => ({
                  label: item.name,
                  value: item.name,
                }))}
                showSearch
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item label="City" name="city">
              <Select
                placeholder="Select city"
                options={City.getAllCities().map((item) => ({
                  label: item.name,
                  value: item.name,
                }))}
                showSearch
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Zip Code"
              name="zipCode"
              required
              rules={[
                {
                  required: true,
                  message: 'This field can not be empty',
                },
              ]}
            >
              <Input placeholder="Zip Code" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Stack justify="flex-end" align="center">
        <Button
          type="primary"
          icon={<SaveOutlined />}
          loading={loading}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  );
}
