import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Dropdown, Space, Timeline } from 'antd';
import { CheckCircleOutlined, MoreOutlined } from '@ant-design/icons';

import { Box, Typo } from '../../../../components/ui';

export default function TradeFlowCard({ trade }) {
  const [tradeStep, setTradeStep] = useState(0);

  useEffect(() => {
    if (trade) {
      let newTradeStep = 0;
      switch (trade.status) {
        case 'requested': {
          newTradeStep = 0;
          break;
        }
        case 'checking': {
          newTradeStep = 1;
          break;
        }
        case 'paying': {
          newTradeStep = 2;
          break;
        }
        case 'shipment': {
          newTradeStep = 3;
          break;
        }
        case 'completed': {
          newTradeStep = 10;
          break;
        }
      }
      setTradeStep(newTradeStep);
    } else {
      setTradeStep(0);
    }
  }, [trade]);

  return (
    <Card
      title="Trade Flow Status"
      style={{ marginBottom: 16 }}
      extra={
        <Space>
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  key: 'key-change-status',
                  label: 'Change Status',
                },
              ],
            }}
          >
            <Button icon={<MoreOutlined />} size="small" />
          </Dropdown>
        </Space>
      }
    >
      <Timeline
        items={[
          {
            color: tradeStep > 0 ? 'green' : 'blue',
            dot: tradeStep > 0 ? <CheckCircleOutlined /> : null,
            children: (
              <Space direction="vertical" size={0}>
                <Typo type="secondary">Step 1</Typo>
                <Typo variant="lg">Request</Typo>
              </Space>
            ),
          },
          {
            color: tradeStep > 1 ? 'green' : tradeStep < 1 ? 'gray' : 'blue',
            dot: tradeStep > 1 ? <CheckCircleOutlined /> : null,
            children: (
              <Space direction="vertical" size={0}>
                <Typo type="secondary">Step 2</Typo>
                <Typo variant="lg">Agreements Executed</Typo>
              </Space>
            ),
          },
          ...(trade?.initialPayments?.length > 0
            ? [
                {
                  color:
                    tradeStep > 2 ? 'green' : tradeStep < 2 ? 'gray' : 'blue',
                  dot: tradeStep > 2 ? <CheckCircleOutlined /> : null,
                  children: (
                    <Space direction="vertical" size={0}>
                      <Typo type="secondary">Step 3</Typo>
                      <Typo variant="lg">Initial Payment</Typo>
                    </Space>
                  ),
                },
              ]
            : []),
          ...(trade?.shipments?.map((shipment, index) => ({
            color:
              tradeStep > 3 || shipment.status === 'settled'
                ? 'green'
                : tradeStep < 3 ||
                    (tradeStep === 3 && shipment.status === 'pending')
                  ? 'gray'
                  : 'blue',
            dot:
              tradeStep > 3 || shipment.status === 'settled' ? (
                <CheckCircleOutlined />
              ) : null,
            style: {
              paddingBottom: 8,
            },
            children: (
              <Box>
                <Space direction="vertical" size={0}>
                  <Typo type="secondary">
                    Step{' '}
                    {4 + index - (trade?.initialPayments?.length === 0 ? 1 : 0)}
                  </Typo>
                  <Typo variant="lg">Shipment {index + 1}</Typo>
                </Space>
                <Timeline
                  style={{
                    marginTop: 16,
                  }}
                  items={[
                    {
                      color:
                        shipment.status === 'manufacturing'
                          ? 'blue'
                          : ['pending'].includes(shipment.status)
                            ? 'gray'
                            : 'green',
                      dot: ['pending', 'manufacturing'].includes(
                        shipment.status,
                      ) ? null : (
                        <CheckCircleOutlined />
                      ),
                      children: (
                        <Space direction="vertical" size={0}>
                          <Typo type="secondary">
                            Step{' '}
                            {4 +
                              index -
                              (trade?.initialPayments?.length === 0 ? 1 : 0)}
                            .1
                          </Typo>
                          <Typo variant="lg">Manufacturing</Typo>
                        </Space>
                      ),
                    },
                    {
                      color:
                        shipment.status === 'shipping'
                          ? 'blue'
                          : ['pending', 'manufacturing'].includes(
                                shipment.status,
                              )
                            ? 'gray'
                            : 'green',
                      dot: ['pending', 'manufacturing', 'shipping'].includes(
                        shipment.status,
                      ) ? null : (
                        <CheckCircleOutlined />
                      ),
                      children: (
                        <Space direction="vertical" size={0}>
                          <Typo type="secondary">
                            Step{' '}
                            {4 +
                              index -
                              (trade?.initialPayments?.length === 0 ? 1 : 0)}
                            .2
                          </Typo>
                          <Typo variant="lg">Arrange Shipping</Typo>
                        </Space>
                      ),
                    },
                    {
                      color:
                        shipment.status === 'delivering'
                          ? 'blue'
                          : ['pending', 'manufacturing', 'shipping'].includes(
                                shipment.status,
                              )
                            ? 'gray'
                            : 'green',
                      dot: [
                        'pending',
                        'manufacturing',
                        'shipping',
                        'delivering',
                      ].includes(shipment.status) ? null : (
                        <CheckCircleOutlined />
                      ),
                      children: (
                        <Space direction="vertical" size={0}>
                          <Typo type="secondary">
                            Step{' '}
                            {4 +
                              index -
                              (trade?.initialPayments?.length === 0 ? 1 : 0)}
                            .3
                          </Typo>
                          <Typo variant="lg">Goods Delivered</Typo>
                        </Space>
                      ),
                    },
                    {
                      color:
                        shipment.status === 'finishing'
                          ? 'blue'
                          : [
                                'pending',
                                'manufacturing',
                                'shipping',
                                'delivering',
                              ].includes(shipment.status)
                            ? 'gray'
                            : 'green',
                      dot: [
                        'pending',
                        'manufacturing',
                        'shipping',
                        'delivering',
                        'finishing',
                      ].includes(shipment.status) ? null : (
                        <CheckCircleOutlined />
                      ),
                      style: {
                        paddingBottom: 0,
                      },
                      children: (
                        <Space direction="vertical" size={0}>
                          <Typo type="secondary">
                            Step{' '}
                            {4 +
                              index -
                              (trade?.initialPayments?.length === 0 ? 1 : 0)}
                            .4
                          </Typo>
                          <Typo variant="lg">Order Finalised</Typo>
                        </Space>
                      ),
                    },
                  ]}
                />
              </Box>
            ),
          })) ?? []),
          {
            color: tradeStep > 4 ? 'green' : tradeStep < 4 ? 'gray' : 'blue',
            dot: tradeStep > 4 ? <CheckCircleOutlined /> : null,
            children: (
              <Space direction="vertical" size={0}>
                <Typo type="secondary">
                  Step{' '}
                  {4 +
                    trade?.shipments?.length -
                    (trade?.initialPayments?.length === 0 ? 1 : 0)}
                </Typo>
                <Typo variant="lg">Trade Completion</Typo>
              </Space>
            ),
            style: {
              paddingBottom: 0,
            },
          },
        ]}
      />
    </Card>
  );
}

TradeFlowCard.propTypes = {
  trade: PropTypes.any,
};
