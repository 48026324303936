import axios from 'axios';
import { notification } from 'antd';
import { handleErrorNotification } from './utilities';

export const apiGetTransactions = async (params) => {
  try {
    const response = await axios.get('/api/admin/v1/transactions', {
      params: params,
    });
    return response.data;
  } catch (e) {
    handleErrorNotification(e);
    return [];
  }
};

export const apiGetTransactionDetails = async (id) => {
  try {
    const response = await axios.get(`/api/admin/v1/transactions/${id}`);
    return response.data;
  } catch (e) {
    handleErrorNotification(e);
    return [];
  }
};

export const apiPatchTransaction = async ({ id, ...data }) => {
  try {
    await axios.patch(`/api/admin/v1/transactions/${id}`, data);
    notification.success({
      message: 'You have updated the transaction successfully',
    });
  } catch (e) {
    handleErrorNotification(e);
    return [];
  }
};
