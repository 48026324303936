import React from 'react';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

import { Box } from '../../components/ui';

const PageBreadcrumbs = () => {
  return (
    <Box mb={0}>
      <Breadcrumb
        items={[
          {
            href: '/',
            title: <HomeOutlined />,
          },
          {
            href: '/businesses',
            title: 'Businesses',
          },
        ]}
        itemRender={(route) => <NavLink to={route.href}>{route.title}</NavLink>}
      />
    </Box>
  );
};

export default PageBreadcrumbs;
