import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar, Button, Dropdown } from 'antd';
import { DownOutlined, LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Typo from '../../ui/Typo';
import Stack from '../../ui/Stack';
import Box from '../../ui/Box';
import { getStoragePath } from '../../../apis/utilities';

const Container = styled('div')(() => ({
  width: '100%',
  height: 64,
  background: 'white',
  boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.08)',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'absolute',
  zIndex: 99,
  left: 0,
  top: 0,
  paddingRight: 24,
  paddingLeft: 24,
}));

const MainHeader = ({ onToggleDrawer }) => {
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const isMd = useMediaQuery({
    query: '(max-width: 992px)',
  });

  const handleSignOut = () => {
    navigate('/auth/logout');
  };

  return (
    <Container>
      <Box>
        {isMd && <Button icon={<MenuOutlined />} onClick={onToggleDrawer} />}
      </Box>
      <Dropdown
        menu={{
          items: [
            {
              key: 'key-logout',
              icon: <LogoutOutlined />,
              label: 'Sign out',
              onClick: handleSignOut,
            },
          ],
        }}
        trigger={['click']}
      >
        <Button
          style={{
            height: 44,
          }}
        >
          <Stack align="center" gap={8}>
            <Avatar src={getStoragePath(auth?.photo)} size={32} />
            <Typo variant="md">{auth?.name ? auth.name : auth?.email}</Typo>
            <DownOutlined />
          </Stack>
        </Button>
      </Dropdown>
    </Container>
  );
};

MainHeader.propTypes = {
  onToggleDrawer: PropTypes.func,
};

export default MainHeader;
