import React from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { resetAuth } from '../../../redux/auth/auth.slice';

export default function LogoutPage() {
  const dispatch = useDispatch();
  dispatch(resetAuth());

  return <Navigate to="/auth/login" />;
}
