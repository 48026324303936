import { Typography } from 'antd';
import {
  space,
  color,
  fontStyle,
  fontFamily,
  fontSize,
  fontWeight,
  layout,
  display,
  flex,
} from 'styled-system';
import styled from 'styled-components';

const Typo = styled(Typography.Text)(
  (props) => ({
    '&.ant-typography': {
      ...(props.variant === 'h1' && {
        fontSize: 48,
        fontWeight: 600,
      }),
      ...(props.variant === 'h2' && {
        fontSize: 40,
        fontWeight: 600,
      }),
      ...(props.variant === 'h3' && {
        fontSize: 32,
        fontWeight: 600,
      }),
      ...(props.variant === 'h4' && {
        fontSize: 24,
        fontWeight: 600,
      }),
      ...(props.variant === 'h5' && {
        fontSize: 20,
        fontWeight: 600,
      }),
      ...(props.variant === 'h6' && {
        fontSize: 18,
        fontWeight: 600,
      }),
      ...(props.variant === 'xl' && {
        fontSize: 18,
      }),
      ...(props.variant === 'lg' && {
        fontSize: 16,
      }),
      ...(props.variant === 'md' && {
        fontSize: 14,
      }),
      ...(props.variant === 'sm' && {
        fontSize: 12,
      }),
      ...(props.variant === 'xs' && {
        fontSize: 10,
      }),
    },
  }),
  space,
  color,
  fontSize,
  fontStyle,
  fontFamily,
  fontWeight,
  layout,
  display,
  flex,
);

Typo.defaultProps = {
  display: 'block',
  variant: 'md',
};

export default Typo;
