import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Upload,
  notification,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getCountries } from 'react-phone-number-input';

import { Box, Typo, UploadWithCropper } from '../../../components/ui';
import SpecificationsInput from './specifications-input.component';
import { apiUploadFile } from '../../../apis/upload';

import labels from 'react-phone-number-input/locale/en';

export default function PageMainForm({
  formInstance,
  onSubmit,
  processing,
  loading,
  companies,
  categories,
}) {
  const handleCustomRequest = (folder) => {
    return (options) => {
      const data = new FormData();
      data.append('files', options.file);
      data.append('key', folder);

      apiUploadFile(data)
        .then((result) => {
          if (result) {
            options.onProgress({
              percent: 100,
            });
            options.onSuccess({
              filename: result,
            });
          } else {
            options.onError(new Error('ERROR_UNKNOWN'));
          }
        })
        .catch((e) => {
          options.onError(e);
        });
    };
  };

  const handleInvalidation = () => {
    notification.error({
      message: 'Please fill all required fields and submit again',
    });
  };

  return (
    <Form
      form={formInstance}
      layout="vertical"
      disabled={processing}
      onFinish={onSubmit}
      onFinishFailed={handleInvalidation}
      scrollToFirstError
    >
      <Form.Item name="id" hidden>
        <Input hidden />
      </Form.Item>
      <Box mt={4}>
        <Spin spinning={loading}>
          <Card title="Product Information">
            <Form.Item
              label="Seller(Business)"
              name="company"
              required
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'The seller must be selected',
                },
              ]}
              extra="Select seller for uploading the product"
            >
              <Select placeholder="Select seller company">
                {companies?.map((item) => (
                  <Select.Option key={`company-${item._id}`} value={item._id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Product Name"
              name="name"
              required
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'The product name must be specified',
                },
              ]}
              extra="Do not exceed 20 characters when entering the product name"
            >
              <Input placeholder="Enter product name" />
            </Form.Item>
            <Form.Item
              label="Category"
              name="category"
              required
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'The product category must be specified',
                },
              ]}
              extra="Please select your product category from the list provided"
            >
              <Select placeholder="Select category">
                {categories
                  .filter((item) => item.parent !== 'Root')
                  .map((item) => (
                    <Select.Option
                      key={`category-${item._id}`}
                      value={item._id}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Location"
              name="location"
              required
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'The product location must be specified',
                },
              ]}
              extra="Please select the location of the product being sold"
            >
              <Select
                placeholder="Select location"
                showSearch
                filterOption={(input, option) =>
                  (option?.children ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {getCountries().map((item) => (
                  <Select.Option key={item} value={item}>
                    {labels[item]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Incoterms"
              name="incoterms"
              required
              rules={[
                {
                  required: true,
                  type: 'array',
                  message: 'At least 1 incoterm should be selected',
                  min: 1,
                },
              ]}
              extra="Please select the available shipping options that is available for this product"
            >
              <Select placeholder="Select incoterms" mode="multiple">
                <Select.Option value="EXW">EXW</Select.Option>
                <Select.Option value="FOB">FOB</Select.Option>
                <Select.Option value="CFR">CFR</Select.Option>
                <Select.Option value="CIF">CIF</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              required
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'The product description is required',
                },
              ]}
              extra="Set a description on product to detail your product and better visibility"
            >
              <ReactQuill
                theme="snow"
                formats={[
                  'header',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'blockquote',
                  'list',
                  'bullet',
                  'indent',
                  'link',
                  'image',
                ]}
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    ['blockquote'],

                    [{ header: 1 }, { header: 2 }],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ script: 'sub' }, { script: 'super' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    [{ direction: 'rtl' }],

                    [{ header: [1, 2, 3, 4, 5, 6, false] }],

                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    [{ align: [] }],
                    ['clean'],
                  ],
                }}
                placeholder="Type description here"
              />
            </Form.Item>
            <Form.Item
              label="Specifications"
              name="specifications"
              required
              rules={[
                {
                  type: 'array',
                  min: 3,
                  required: true,
                  message: 'This field is required',
                },
              ]}
              extra="Set a description on product to detail your product and better visibility"
              valuePropName="items"
              getValueFromEvent={(items) => items}
            >
              <SpecificationsInput />
            </Form.Item>
            <Form.Item
              label="Photo Product"
              name="photos"
              required
              rules={[
                {
                  required: true,
                  type: 'array',
                  min: 1,
                  max: 10,
                  message: 'Please attach 1 ~ 10 product images',
                },
              ]}
              valuePropName="files"
              getValueFromEvent={(info) => info}
            >
              <UploadWithCropper
                cropProps={{
                  aspect: 3 / 2,
                  quality: 1,
                }}
                uploadProps={{
                  listType: 'picture-card',
                  name: 'file',
                }}
                folder={'products/photos'}
              >
                <div>
                  <UploadOutlined />
                  <Typo>Upload</Typo>
                </div>
              </UploadWithCropper>
            </Form.Item>
            <Form.Item
              label="Video"
              name="videos"
              required
              rules={[
                {
                  required: true,
                  type: 'array',
                  min: 1,
                  message: 'Please attach at least 1 product video',
                },
              ]}
              valuePropName="fileList"
              getValueFromEvent={(info) => {
                if (Array.isArray(info)) {
                  return info;
                }
                return info?.fileList;
              }}
            >
              <Upload
                maxCount={1}
                listType="picture-card"
                name="file"
                multiple
                accept="video/*"
                action="/api/v1/upload"
                customRequest={handleCustomRequest('products/videos')}
              >
                <div>
                  <UploadOutlined />
                  <Typo>Upload</Typo>
                </div>
              </Upload>
            </Form.Item>
            <Form.Item
              label="Attachments"
              name="attachments"
              required
              rules={[
                {
                  required: true,
                  type: 'array',
                  message: 'Please attach at least 1 product attachment file',
                  min: 1,
                },
              ]}
              valuePropName="fileList"
              getValueFromEvent={(info) => {
                if (Array.isArray(info)) {
                  return info;
                }
                return info?.fileList;
              }}
            >
              <Upload
                maxCount={10}
                listType="text"
                name="file"
                multiple
                action="/api/v1/upload"
                customRequest={handleCustomRequest('products/attachments')}
              >
                <Button type="primary" icon={<UploadOutlined />}>
                  Choose a file...
                </Button>
              </Upload>
            </Form.Item>
          </Card>
        </Spin>
        <Spin spinning={loading}>
          <Card
            title="Price"
            style={{
              marginTop: 16,
            }}
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Price"
                  name="price"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'The product price must be specified',
                    },
                  ]}
                >
                  <Input type="number" placeholder="0.00" addonBefore="$" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Unit"
                  name="unit"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Select placeholder="Select unit">
                    <Select.Option value="CBM">CBM (Cubic Meter)</Select.Option>
                    <Select.Option value="MT">MT (Metric Ton)</Select.Option>
                    <Select.Option value="BBL">BBL (Barrel)</Select.Option>
                    <Select.Option value="LB">LB (Pound)</Select.Option>
                    <Select.Option value="KG">KG (Kilogram)</Select.Option>
                    <Select.Option value="TON">TON (Short Ton)</Select.Option>
                    <Select.Option value="PC">PC (Piece)</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Minimum Order Quantity"
              name="minimumOrderQuantity"
              required
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input type="number" placeholder="0.00" />
            </Form.Item>
          </Card>
        </Spin>
      </Box>
    </Form>
  );
}

PageMainForm.propTypes = {
  formInstance: PropTypes.any,
  loading: PropTypes.bool,
  processing: PropTypes.bool,
  companies: PropTypes.array,
  categories: PropTypes.array,
  onSubmit: PropTypes.func,
};
