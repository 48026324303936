const ErrorMessages = {
  ERROR_UNKNOWN: 'Something went wrong, please try again later.',
  ERROR_USER_NO_REGISTERED: 'Your account is not registered',
  ERROR_WRONG_PASSWORD: 'Please confirm your password again',
  ERROR_USER_ALREADY_EXISTS: 'Your email address is already exist',
  ERROR_EMAIL_SENT_FAILED:
    'Sorry, we failed to send your verification email. Please try again later.',
  ERROR_INVALID_VERIFICATION_CODE:
    'The provided verification code is incorrect',
  ERROR_VERIFICATION_CODE_EXPIRED:
    'The provided verification code is expired, please try with a new verification code',
  ERROR_EMAIL_NOT_EXIST: 'Your account is not registered',
  ERROR_INVALID_EMAIL: 'Invalid email address is provided',
  ERROR_EMAIL_NOT_VERIFIED: 'You need to verify your email address first',
  ERROR_CATEGORY_NOT_FOUND:
    'The selected category is not exist in the database',
  ERROR_NO_FILE_UPLOADED: 'Please upload file',
  ERROR_PRODUCT_NOT_FOUND: 'The product is not exist in the database',
  ERROR_NOT_FOUND: 'The requested resource is not exist in the database',
};

export default ErrorMessages;
