import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Input, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { Typo, Box } from '../../../components/ui';

export default function SpecificationsInput({ items, onChange }) {
  const values = items ? items : [];
  if (values.length < 3) {
    do {
      values.push({
        specification: '',
        value: '',
      });
    } while (values.length < 3);
  }

  const handleChange = (index, field, value) => {
    const newValues = [...values];
    newValues[index][field] = value;
    onChange(newValues);
  };

  const handleAddMore = () => {
    const newValues = [...values];
    newValues.push({
      specification: '',
      value: '',
    });
    onChange(newValues);
  };

  return (
    <Box mb={1}>
      <Flex gap={12}>
        <Typo flex={1}>Specification</Typo>
        <Typo flex={1}>Value</Typo>
      </Flex>
      {values.map((item, index) => (
        <Box key={`spec-input-${index}`} mt={1}>
          <Flex gap={12}>
            <Input
              placeholder="Enter specification"
              style={{
                flex: 1,
              }}
              value={item.specification}
              onChange={(ev) =>
                handleChange(index, 'specification', ev.currentTarget.value)
              }
            />
            <Input
              placeholder="Enter value"
              style={{
                flex: 1,
              }}
              value={item.value}
              onChange={(ev) =>
                handleChange(index, 'value', ev.currentTarget.value)
              }
            />
          </Flex>
        </Box>
      ))}
      <Button
        icon={<PlusOutlined />}
        size="small"
        style={{
          marginTop: 8,
        }}
        onClick={handleAddMore}
      >
        Add more
      </Button>
    </Box>
  );
}

SpecificationsInput.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
};
