import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Card, Divider, Space, Tag } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import { Box, Stack, Typo } from '../../../../components/ui';
import { getStoragePath } from '../../../../apis/utilities';
import UserDetailsView from '../../../../views/user/user.view';
import CompanyDetailsView from '../../../../views/company/company.view';

export default function BusinessInfoCard({ title, id, name, logo, members }) {
  const [businessView, setBusinessView] = useState({
    open: false,
    id: null,
  });
  const [userView, setUserView] = useState({
    open: false,
    id: null,
  });

  const handleOpenCompanyView = (id) => {
    setBusinessView({
      id: id,
      open: true,
    });
  };

  const handleOpenUserView = (id) => {
    setUserView({
      id: id,
      open: true,
    });
  };

  const handleCloseCompanyView = () => {
    setBusinessView((values) => ({
      ...values,
      open: false,
    }));
  };

  const handleCloseUserView = () => {
    setUserView((values) => ({
      ...values,
      open: false,
    }));
  };

  return (
    <>
      <Card
        title={title}
        style={{
          marginBottom: 16,
        }}
      >
        <Stack justify="space-between" align="center">
          <Space>
            <Avatar src={getStoragePath(logo)} />
            <Typo>{name}</Typo>
          </Space>
          <Button
            icon={<EyeOutlined />}
            size="small"
            onClick={() => handleOpenCompanyView(id)}
          />
        </Stack>
        <Divider
          style={{
            margin: '16px 0',
          }}
        />
        {members?.map((item, index) => (
          <Stack key={`member-${index}`} justify="space-between" align="center">
            <Space>
              <Avatar src={getStoragePath(item.photo)} />
              <Box>
                <Typo>
                  {item.firstName} {item.lastName}
                </Typo>
                <Typo type="secondary">{item.email}</Typo>
              </Box>
            </Space>
            <Button
              icon={<EyeOutlined />}
              size="small"
              onClick={() => handleOpenUserView(item._id)}
            />
          </Stack>
        ))}
        {!members || members.length < 1 ? <Tag>No Assigned Members</Tag> : null}
      </Card>

      <UserDetailsView
        open={userView.open}
        onClose={handleCloseUserView}
        id={userView.id}
      />
      <CompanyDetailsView
        open={businessView.open}
        onClose={handleCloseCompanyView}
        id={businessView.id}
      />
    </>
  );
}

BusinessInfoCard.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.string,
  members: PropTypes.array,
};
