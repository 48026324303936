import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Modal, Select } from 'antd';
import { CheckOutlined, UploadOutlined } from '@ant-design/icons';

import { Typo, UploadWithCropper } from '../../components/ui';
import constants from '../../config/constants';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function EditCategoryModal({
  initialValues,
  open,
  onSubmit,
  onCancel,
  loading,
  categories,
}) {
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [uploadKey, setUploadKey] = useState(Date.now());

  const handleSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const handlePreview = useCallback(async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewOpen(true);
    setPreviewImage(file.url || file.preview);
  }, []);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        id: initialValues._id ? initialValues._id : '',
        name: initialValues.name,
        image: initialValues.image
          ? [
              {
                uid: -1,
                name: initialValues.image,
                status: 'done',
                url: constants.STORAGE_HOST + '/' + initialValues.image,
              },
            ]
          : [],
        description: initialValues.description ? initialValues.description : '',
        parent:
          initialValues.parent && initialValues.parent !== 'Root'
            ? initialValues.parent
            : null,
        status: initialValues.status,
      });
    } else {
      form.setFieldsValue({
        id: '',
        name: '',
        image: [],
        description: '',
        parent: '',
        status: 'active',
      });
    }

    setUploadKey(Date.now());
  }, [form, initialValues]);

  return (
    <>
      <Modal
        title={initialValues?._id ? 'Edit Category' : 'Add New Category'}
        open={open}
        okButtonProps={{
          type: 'primary',
          icon: <CheckOutlined />,
          loading: loading,
        }}
        okText="Submit"
        onOk={handleSubmit}
        onCancel={onCancel}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          style={{
            marginTop: 24,
          }}
          disabled={loading}
        >
          <Form.Item hidden name="id">
            <Input hidden />
          </Form.Item>
          <Form.Item
            label="Category Name"
            name="name"
            required
            rules={[
              {
                required: true,
                type: 'string',
                message: 'The category name must be specified',
              },
            ]}
          >
            <Input placeholder="Type category name" />
          </Form.Item>
          <Form.Item
            label="Image"
            name="image"
            required
            rules={[
              {
                required: true,
                type: 'array',
                len: 1,
                message: 'The category image must be uploaded',
              },
            ]}
            valuePropName="files"
            getValueFromEvent={(files) => files}
          >
            <UploadWithCropper
              key={uploadKey}
              cropProps={{
                aspect: 3 / 2,
                quality: 1,
              }}
              uploadProps={{
                name: 'image',
                listType: 'picture-card',
                onPreview: handlePreview,
              }}
              folder="categories"
            >
              <div>
                <UploadOutlined />
                <Typo>Upload</Typo>
              </div>
            </UploadWithCropper>
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            required
            rules={[
              {
                required: true,
                type: 'string',
                message: 'This field is required',
              },
            ]}
          >
            <Input.TextArea
              placeholder="This is a description text..."
              rows={4}
            />
          </Form.Item>
          <Form.Item
            label="Parent Category"
            name="parent"
            rules={[
              {
                required: false,
                type: 'string',
              },
            ]}
          >
            <Select showSearch placeholder="Select parent category" allowClear>
              {categories
                .filter(
                  (item) =>
                    item.parent === 'Root' && item.name !== initialValues?.name,
                )
                .map((item) => (
                  <Select.Option
                    value={item.name}
                    key={`choose-category-${item._id}`}
                  >
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            required
            rules={[
              {
                required: true,
                type: 'string',
                message: 'The status must be selected',
              },
            ]}
          >
            <Select placeholder="Select status">
              <Select.Option value="active">Enabled</Select.Option>
              <Select.Option value="inactive">Disabled</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={previewOpen}
        title="Preview Image"
        footer={null}
        onCancel={() => {
          setPreviewOpen(false);
          setPreviewImage('');
        }}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
}

EditCategoryModal.propTypes = {
  initialValues: PropTypes.any,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  categories: PropTypes.array,
};
