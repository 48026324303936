import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Spin, Tabs } from 'antd';
import {
  IdcardOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useQuery } from 'react-query';

import { UserViewContext } from './user.context';
import UserAccountTab from './account.tab';
import UserBusinessTab from './business.tab';
import UserIdentificationsTab from './identifications.tab';

import { apiGetUserDetails } from '../../apis/users';

export default function UserDetailsView({ id, open, onClose, onAfterSubmit }) {
  const { data, isLoading, refetch } = useQuery(['users', id], async () => {
    if (id) {
      return await apiGetUserDetails(id);
    } else {
      return null;
    }
  });

  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="right"
      title="User Details"
      width={800}
    >
      <UserViewContext.Provider
        value={{
          user: data,
          reload: refetch,
          onAfterSubmit,
        }}
      >
        <Spin spinning={isLoading}>
          <Tabs
            items={[
              {
                key: 'tab-account',
                label: 'Account',
                icon: <UserOutlined />,
                children: <UserAccountTab />,
              },
              {
                key: 'tab-company',
                label: 'Business',
                icon: <InfoCircleOutlined />,
                children: <UserBusinessTab />,
              },
              {
                key: 'tab-identifications',
                label: 'Identification',
                icon: <IdcardOutlined />,
                children: <UserIdentificationsTab />,
              },
            ]}
            tabPosition="left"
          />
        </Spin>
      </UserViewContext.Provider>
    </Drawer>
  );
}

UserDetailsView.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAfterSubmit: PropTypes.func,
};
