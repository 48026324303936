import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const pairs = {
  superadmin: {
    color: 'green',
    label: 'Super Admin',
  },
  admin: {
    color: 'green',
    label: 'Admin',
  },
  owner: {
    color: 'purple',
    label: 'Business Owner',
  },
  agent: {
    color: 'gray',
    label: 'Agent',
  },
};

export default function UserRoleTag({ role }) {
  return <Tag color={pairs[role]?.color}>{pairs[role]?.label}</Tag>;
}

UserRoleTag.propTypes = {
  role: PropTypes.string,
};
