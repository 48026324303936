import React, { useState } from 'react';
import { Avatar, Button, Empty, List, Tag } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Box, Stack, Typo } from '../../components/ui';
import { useUserViewModel } from './user.context';
import { apiPatchUser } from '../../apis/users';

export default function UserIdentificationsTab() {
  const { user, onAfterSubmit, reload } = useUserViewModel();

  const [loading, setLoading] = useState(false);

  const handleToggleStatus = async (name) => {
    setLoading(true);
    if (user) {
      const documents = user.documents;
      if (documents) {
        documents.identification.file = documents.identification.file._id;
        documents.proofOfAddress.file = documents.proofOfAddress.file._id;
        documents.bankStatement.file = documents.bankStatement.file._id;

        documents[name].status =
          documents[name].status === 'verified' ? 'pending' : 'verified';
        await apiPatchUser(user._id, {
          documents,
        });
        await reload();
        onAfterSubmit();
      }
    }
    setLoading(false);
  };

  return (
    <Box>
      <Stack justify="space-between" align="center" mb={3}>
        <Typo variant="h4">User Identification Documents</Typo>
        <Tag color={user?.isVerified ? 'green' : 'red'}>
          {user?.isVerified ? 'Verified' : 'In Review'}
        </Tag>
      </Stack>
      <Box>
        {user?.documents &&
        user?.documents?.identification?.file &&
        user?.documents?.proofOfAddress?.file &&
        user?.documents?.bankStatement?.file ? (
          <List loading={loading}>
            {['identification', 'proofOfAddress', 'bankStatement'].map(
              (key) => (
                <List.Item
                  key={`doc-${key}`}
                  actions={[
                    <Button
                      key="download-id"
                      size="small"
                      href={user?.documents[key]?.file?.downloadUrl}
                      download={user?.documents[key]?.file?.filename}
                      target="_blank"
                    >
                      Download
                    </Button>,
                    <Button
                      key="toggle-status"
                      size="small"
                      type="primary"
                      danger={
                        user?.documents[key]?.status === 'verified'
                          ? true
                          : false
                      }
                      onClick={() => handleToggleStatus(key)}
                    >
                      {user?.documents[key]?.status === 'verified'
                        ? 'Reject'
                        : 'Confirm'}
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<Avatar icon={<FilePdfOutlined />} />}
                    title={
                      <a
                        href={user?.documents[key]?.file?.downloadUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {key === 'identification'
                          ? 'Identification'
                          : key === 'proofOfAddress'
                            ? 'Proof of Address'
                            : 'Bank Statement'}
                      </a>
                    }
                    description={`uploaded at ${dayjs(
                      user?.documents[key]?.file?.createdAt,
                    ).format('YYYY-MM-DD')}`}
                  />
                  <Tag
                    color={
                      user?.documents[key]?.status === 'verified'
                        ? 'green'
                        : 'blue'
                    }
                  >
                    {user?.documents[key]?.status === 'verified'
                      ? 'Verified'
                      : 'Pending'}
                  </Tag>
                </List.Item>
              ),
            )}
          </List>
        ) : (
          <Empty
            style={{
              marginTop: 24,
            }}
          />
        )}
      </Box>
    </Box>
  );
}
