import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const pairs = {
  requested: {
    color: 'blue',
    label: 'New Order',
  },
  checking: {
    color: 'blue',
    label: 'In Agreement Steps',
  },
  paying: {
    color: 'orange',
    label: 'In Payment Steps',
  },
  shipment: {
    color: 'blue',
    label: 'In Shipment Stages',
  },
  rejected: {
    color: 'red',
    label: 'Rejected',
  },
  cancelled: {
    color: 'red',
    label: 'Cancelled',
  },
  completed: {
    color: 'green',
    label: 'Completed',
  },
};

export default function TradeStatusTag({ status }) {
  return <Tag color={pairs[status]?.color}>{pairs[status]?.label}</Tag>;
}

TradeStatusTag.propTypes = {
  status: PropTypes.string,
};
