import { Card as AntCard } from 'antd';
import styled from 'styled-components';
import { space, layout } from 'styled-system';

const Card = styled(AntCard)`
  ${space}
  ${layout}
`;

export default Card;
