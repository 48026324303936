import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';
import { Upload } from 'antd';

export default function UploadWithCropper({
  files,
  onChange,
  children,
  uploadProps,
  cropProps,
  folder,
}) {
  const [key, setKey] = useState(Date.now);

  const handleChange = useCallback(
    (info) => {
      if (onChange) {
        onChange(info.fileList);
      }
    },
    [onChange],
  );

  const onCropped = useCallback(() => {
    return false;
  }, []);

  const onCustomRequest = useCallback((options) => {
    options.onSuccess({});
    return false;
  }, []);

  const onDirectUpload = useCallback(
    (options) => {
      const data = new FormData();
      data.append('files', options.file);
      data.append('key', folder);
      axios
        .post(options.action, data)
        .then((response) => {
          if (response.data?.data) {
            options.onProgress({
              percent: 100,
            });
            options.onSuccess({
              filename: response.data.data,
            });
          } else {
            options.onError(new Error('ERROR_UNKNOWN'));
          }
        })
        .catch((e) => {
          options.onError(e);
        });
    },
    [folder],
  );

  useEffect(() => {
    setKey(Date.now());
  }, [files]);

  return (
    <ImgCrop {...cropProps} onModalOk={onCropped}>
      <Upload
        key={key}
        defaultFileList={files}
        beforeUpload={() => false}
        onChange={handleChange}
        customRequest={onCustomRequest}
        {...uploadProps}
        {...(folder && {
          action: '/api/v1/upload',
          customRequest: onDirectUpload,
        })}
      >
        {children}
      </Upload>
    </ImgCrop>
  );
}

UploadWithCropper.propTypes = {
  files: PropTypes.any,
  onChange: PropTypes.func,
  children: PropTypes.any,
  uploadProps: PropTypes.any,
  cropProps: PropTypes.any,
  folder: PropTypes.string,
};
