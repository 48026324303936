import { Flex } from 'antd';
import {
  space,
  border,
  layout,
  size,
  padding,
  margin,
  position,
} from 'styled-system';
import styled from 'styled-components';

const Stack = styled(Flex)`
  ${space}
  ${border}
  ${layout}
  ${size}
  ${padding}
  ${margin}
  ${position}
`;

export default Stack;
