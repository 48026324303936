import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Table } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { Box } from '../../../components/ui';

export default function ProductsInput({ items, onChange }) {
  const handleAddItem = () => {
    const newItems = [...items];
    newItems.push({
      name: '',
      quantity: 0,
      price: 0,
      tax: 0,
      total: 0,
    });
    onChange(newItems);
  };

  const handleChangeItem = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    onChange(newItems);
  };

  const handleRemoveItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    onChange(newItems);
  };

  return (
    <Box>
      <Table
        dataSource={items}
        columns={[
          {
            dataIndex: 'name',
            title: 'Product',
            width: 200,
            render: (value, row, index) => (
              <Input
                placeholder="Product Name"
                value={value}
                onChange={(ev) =>
                  handleChangeItem(index, 'name', ev.target.value)
                }
              />
            ),
          },
          {
            dataIndex: 'quantity',
            title: 'Qty',
            width: 70,
            render: (value, row, index) => (
              <Input
                type="number"
                placeholder="Quantity"
                value={value}
                onChange={(ev) =>
                  handleChangeItem(index, 'quantity', ev.target.value)
                }
              />
            ),
          },
          {
            dataIndex: 'price',
            title: 'Unit Price',
            width: 70,
            render: (value, row, index) => (
              <Input
                type="number"
                placeholder="Unit Price"
                prefix="$"
                value={value}
                onChange={(ev) =>
                  handleChangeItem(index, 'price', ev.target.value)
                }
              />
            ),
          },
          {
            dataIndex: 'tax',
            title: 'Tax',
            width: 70,
            render: (value, row, index) => (
              <Input
                type="number"
                placeholder="Tax"
                prefix="%"
                value={value}
                onChange={(ev) =>
                  handleChangeItem(index, 'tax', ev.target.value)
                }
              />
            ),
          },
          {
            dataIndex: 'total',
            title: 'Total',
            width: 70,
            render: (value, row, index) => (
              <Input
                type="number"
                placeholder="Total"
                prefix="$"
                value={value}
                onChange={(ev) =>
                  handleChangeItem(index, 'total', ev.target.value)
                }
              />
            ),
          },
          {
            dataIndex: '_',
            title: '',
            width: 20,
            align: 'right',
            render: (value, row, index) => (
              <Button
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => handleRemoveItem(index)}
              />
            ),
          },
        ]}
        pagination={{
          position: ['none', 'none'],
        }}
        size="small"
      />
      <Box mt={3}>
        <Button size="small" icon={<PlusOutlined />} onClick={handleAddItem}>
          Add Product
        </Button>
      </Box>
    </Box>
  );
}

ProductsInput.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
};
