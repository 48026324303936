import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const pairs = {
  active: {
    color: 'green',
    label: 'Active',
  },
  disabled: {
    color: 'red',
    label: 'Disabled',
  },
};

export default function UserStatusTag({ status }) {
  return <Tag color={pairs[status]?.color}>{pairs[status]?.label}</Tag>;
}

UserStatusTag.propTypes = {
  status: PropTypes.string,
};
