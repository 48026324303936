import React from 'react';
import { Avatar, List } from 'antd';

import { Box, Typo } from '../../components/ui';
import UserRoleTag from '../../components/tags/UserRoleTag';

import { useCompanyViewModel } from './company.context';
import { getStoragePath } from '../../apis/utilities';

export default function BusinessMembersTab() {
  const { company } = useCompanyViewModel();

  return (
    <Box>
      <Typo variant="h4" mb={3}>
        Business Members
      </Typo>
      <List
        dataSource={company?.members ?? []}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={getStoragePath(item.photo)} />}
              title={(item.firstName ?? '') + ' ' + (item.lastName ?? '')}
              description={item.email}
            />
            <UserRoleTag role={item.role} />
          </List.Item>
        )}
      />
    </Box>
  );
}
