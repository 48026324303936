import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Image, Popconfirm, Space, Switch, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import querystring from 'query-string';

import { Box, Typo } from '../../components/ui';
import TextFilterDropdown from '../../components/mixed/TextFilterDropdown';
import constants from '../../config/constants';

export default function PageCategoriesView({
  data,
  isLoading,
  onDelete,
  onEdit,
  onToggleStatus,
}) {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});

  const handleChangeTable = (pagination, filters, sorter) => {
    const params = {
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      ...filters,
      sorter:
        sorter?.order === 'ascend' || sorter?.order === 'descend'
          ? [sorter.field, sorter.order]
          : null,
    };
    const query = querystring.stringify(params, {
      arrayFormat: 'separator',
      skipNull: true,
    });
    navigate(`/categories?${query}`);
  };

  useEffect(() => {
    const queries = querystring.parse(params.toString(), {
      arrayFormat: 'separator',
    });
    setPagination({
      ...pagination,
      current: queries.pageIndex ? +queries.pageIndex : 1,
      pageSize: queries.pageSize ? +queries.pageSize : 10,
    });
    setFilters({
      _id: queries._id ? [queries._id] : null,
      name: queries.name ? [queries.name] : null,
      parent: queries.parent
        ? typeof queries.parent === 'string'
          ? [queries.parent]
          : queries.parent
        : null,
      status: queries.status
        ? typeof queries.status === 'string'
          ? [queries.status]
          : queries.status
        : null,
    });
    setSorter(
      queries.sorter?.length === 2
        ? {
            field: queries.sorter[0],
            order: queries.sorter[1],
          }
        : {},
    );
  }, [params]);

  return (
    <Box mt={4}>
      <Table
        dataSource={data}
        loading={isLoading}
        columns={[
          {
            dataIndex: '_id',
            title: 'Category ID',
            render: (value, row) => (
              <Button type="link" onClick={() => onEdit(row)}>
                #{value.substr(-6).toUpperCase()}
              </Button>
            ),
            sorter: (a, b) => (a._id > b._id ? 1 : -1),
            sortOrder: sorter.field === '_id' ? sorter.order : null,
            filterDropdown: (props) => (
              <TextFilterDropdown {...props} placeholder="Search category ID" />
            ),
            onFilter: (value, record) =>
              record._id.toLowerCase().includes(value.toLowerCase()),
            filteredValue: filters._id ?? null,
          },
          {
            dataIndex: 'name',
            title: 'Category Name',
            render: (value, row) => (
              <Space size={8}>
                <Image
                  src={`${constants.STORAGE_HOST}/${row.image}`}
                  width={60}
                  height={40}
                  style={{
                    objectFit: 'cover',
                  }}
                />
                <Typo>{value}</Typo>
              </Space>
            ),
            filterDropdown: (props) => (
              <TextFilterDropdown
                {...props}
                placeholder="Search category name"
              />
            ),
            onFilter: (value, record) =>
              record.name.toLowerCase().includes(value.toLowerCase()),
            filteredValue: filters.name ?? null,
          },
          {
            dataIndex: 'numberOfProducts',
            title: 'No. of Products',
            sorter: false,
            render: (value) => {
              if (value) {
                return value;
              }
              return null;
            },
          },
          {
            dataIndex: 'parent',
            title: 'Parent Category',
            filters: [
              {
                value: 'Root',
                text: 'Root',
              },
              ...data
                .filter((item) => item.parent === 'Root')
                .map((item) => ({
                  value: item.name,
                  text: item.name,
                })),
            ],
            onFilter: (value, record) => record.parent === value,
            filteredValue: filters.parent ?? null,
          },
          {
            dataIndex: 'status',
            title: 'Status',
            render: (value, row) => (
              <Switch
                checked={value === 'active'}
                onChange={(checked) => onToggleStatus(row._id, checked)}
              />
            ),
            filters: [
              { value: 'active', text: 'Enabled' },
              { value: 'inactive', text: 'Disabled' },
            ],
            onFilter: (value, record) => record.status === value,
            filteredValue: filters.status ?? null,
          },
          {
            dataIndex: '_id',
            title: '',
            render: (value, record) => (
              <Space size={8}>
                <Button
                  size="small"
                  icon={<EditOutlined />}
                  onClick={() => onEdit(record)}
                />
                <Popconfirm
                  title="Are you sure to delete?"
                  okText="Confirm"
                  onConfirm={() => onDelete(value)}
                >
                  <Button size="small" danger icon={<DeleteOutlined />} />
                </Popconfirm>
              </Space>
            ),
          },
        ]}
        rowKey={(row) => `category-${row._id}`}
        pagination={pagination}
        onChange={handleChangeTable}
      />
    </Box>
  );
}

PageCategoriesView.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onToggleStatus: PropTypes.func,
};
