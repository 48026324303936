import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PageBreadcrumbs from './page-breadcrumbs.component';
import PageHeader from './page-header.component';
import PageTradesView from './page-trades-view.component';

import { apiGetTrades } from '../../../apis/trades';
import { apiGetCompanies } from '../../../apis/companies';

export default function TradeManagementPage() {
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const pageIndex = params.get('pageIndex')
    ? parseInt(params.get('pageIndex'))
    : 1;
  const pageSize = params.get('pageSize')
    ? parseInt(params.get('pageSize'))
    : 10;
  const search = params.get('search') ? params.get('search') : '';
  const seller = params.get('seller') ? params.get('seller') : '';
  const buyer = params.get('buyer') ? params.get('buyer') : '';
  const incoterms = params.get('incoterms') ? params.get('incoterms') : '';
  const status = params.get('status') ? params.get('status') : '';
  const sorter = params.get('sorter') ? params.get('sorter') : '';
  const [sortBy, sortOrder] = sorter.split(',');

  const { data, isLoading } = useQuery(
    [
      'trades',
      pageIndex,
      pageSize,
      search,
      seller,
      buyer,
      incoterms,
      status,
      sortOrder,
      sortBy,
    ],
    async () => {
      return await apiGetTrades({
        pageIndex,
        pageSize,
        search,
        seller,
        buyer,
        incoterms,
        status,
        ...(sortBy &&
          (sortOrder === 'ascend' || sortOrder === 'descend') && {
            sortBy,
            sortOrder: sortOrder === 'ascend' ? 'asc' : 'desc',
          }),
      });
    },
  );
  const { data: companies } = useQuery(['companies'], apiGetCompanies);

  const handleEdit = (id) => {
    navigate(`/trades/${id}`);
  };

  return (
    <>
      <PageBreadcrumbs />
      <PageHeader />
      <PageTradesView
        data={data}
        isLoading={isLoading}
        companies={companies ?? []}
        onEdit={handleEdit}
      />
    </>
  );
}
