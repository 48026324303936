import React, { useState } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import MainSideBar from './sidebar.component';
import MainHeader from './header.component';
import MainContent from './content.component';

const MainLayout = () => {
  const [open, setOpen] = useState(false);

  const handleToggleDrawer = () => {
    setOpen((value) => !value);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  return (
    <Layout>
      <MainSideBar open={open} onClose={handleCloseDrawer} />
      <Layout
        style={{
          position: 'relative',
        }}
      >
        <MainHeader onToggleDrawer={handleToggleDrawer} />
        <MainContent>
          <Outlet />
        </MainContent>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
