import React from 'react';
import PropTypes from 'prop-types';
import { Card, Descriptions } from 'antd';
import TransactionStatusTag from '../../../components/tags/TransactionStatusTag';
import dayjs from 'dayjs';

export default function PageTransactionSummaryCard({ transaction }) {
  return (
    <Card
      title="Transaction Overview"
      style={{
        marginBottom: 16,
      }}
    >
      <Descriptions
        layout="horizontal"
        items={[
          {
            key: 'key-id',
            label: 'Transaction ID',
            children: <a>#{transaction?._id}</a>,
          },
          {
            key: 'key-status',
            label: 'Status',
            children: <TransactionStatusTag status={transaction?.status} />,
          },
          {
            key: 'key-paidAt',
            label: 'Paid At',
            children: transaction?.paidAt
              ? dayjs(transaction.paidAt).format('DD MMM, YYYY HH:mm')
              : '',
          },
          {
            key: 'key-paidAt',
            label: 'Last Updated At',
            children: transaction?.updatedAt
              ? dayjs(transaction.updatedAt).format('DD MMM, YYYY HH:mm')
              : '',
          },
          {
            key: 'key-recipient-address',
            label: 'Recipient Address',
            children: '0x45dEc58cA96E5b71b8e89eac',
          },
          {
            key: 'key-originating-wallet',
            label: 'Originating Wallet',
            children: '0x45dEc58cA96E5b71b8e89eac',
          },
          {
            key: 'key-block-confirmation',
            label: 'Block Confirmation',
            children: '13',
          },
          {
            key: 'key-contract-address',
            label: 'Contract Address',
            children: "['Not Applicable']",
          },
          {
            key: 'key-transaction-hash',
            label: 'Transaction Hash',
            children: '0x45dEc58cA96E5b71b8e89eac',
          },
          {
            key: 'key-amount',
            label: 'Amount',
            children: '$' + Number(transaction?.total).toLocaleString(),
          },
          {
            key: 'key-fee',
            label: 'Fee',
            children: '$' + Number(transaction?.fee).toLocaleString(),
          },
          {
            key: 'key-link-chain',
            label: 'Link to Chain',
            children: <a>View on Blockchain Explorer</a>,
          },
        ]}
      />
    </Card>
  );
}

PageTransactionSummaryCard.propTypes = {
  transaction: PropTypes.any,
};
