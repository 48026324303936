import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'antd';
import { EyeOutlined, MoreOutlined } from '@ant-design/icons';

export default function ActionMenu({ id, onEdit }) {
  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              label: 'View Business',
              icon: <EyeOutlined />,
              onClick: () => onEdit(id),
            },
          ],
        }}
        trigger={['click']}
      >
        <Button icon={<MoreOutlined />} size="small" />
      </Dropdown>
    </>
  );
}

ActionMenu.propTypes = {
  id: PropTypes.any,
  onEdit: PropTypes.func,
};
