import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Card, Dropdown, Space, Table } from 'antd';
import {
  DollarCircleOutlined,
  FileOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { Box, Stack, Typo } from '../../../../components/ui';
import DocumentItem from './document-item.component';
import PaymentStatusTag from '../../../../components/tags/PaymentStatusTag';
import { getStoragePath } from '../../../../apis/utilities';

export default function TradeShipmentStageCard({
  title,
  shipment,
  trade,
  onUploadDocument,
}) {
  const navigate = useNavigate();

  const total = useMemo(() => {
    let sum = 0;
    for (const row of shipment.items) {
      sum += row.total;
    }
    return sum;
  }, [shipment]);

  const handleOpenTradeBuilder = () => {
    navigate('trade-builder');
  };

  return (
    <Card
      title={title}
      extra={
        <Space>
          <Button type="link" size="small" onClick={handleOpenTradeBuilder}>
            Edit Shipment Stage
          </Button>
        </Space>
      }
      style={{
        marginBottom: 16,
      }}
    >
      <Stack align="center" gap={4} mb={2}>
        <InfoCircleOutlined />
        <Typo fontWeight={600}>Cargo</Typo>
      </Stack>
      <Table
        dataSource={shipment.items}
        columns={[
          {
            dataIndex: 'name',
            title: 'Product',
            render: (value, row) => (
              <Space>
                <Avatar src={getStoragePath(row.image)} shape="square" />
                <Typo>{row.name}</Typo>
              </Space>
            ),
          },
          {
            dataIndex: 'quantity',
            title: 'Qty',
            align: 'right',
            render: (value, row) =>
              `${Number(row.quantity).toLocaleString()} ${row.unit}`,
          },
          {
            dataIndex: 'total',
            title: 'Total',
            align: 'right',
            render: (value) => `$${Number(value).toLocaleString()}`,
          },
        ]}
        footer={() => (
          <Stack justify="flex-end">
            <Typo>
              Total:{' '}
              <span style={{ fontWeight: 'bold' }}>
                ${total.toLocaleString()}
              </span>
            </Typo>
          </Stack>
        )}
        rowKey={(item) => item._id}
        size="small"
        pagination={{
          pageSize: 100,
          position: ['none', 'none'],
        }}
      />
      <Stack align="center" gap={4} mt={4}>
        <FileOutlined />
        <Typo fontWeight={600}>Manufacturing Documents</Typo>
      </Stack>
      <Stack vertical gap={12} mt={3}>
        {trade.documents
          .filter(
            (item) =>
              item.shipmentId === shipment._id &&
              item.type === 'manufacturing-document',
          )
          .map((item, index) => (
            <DocumentItem
              key={`manufacturing-${index}`}
              name={item.name}
              path={item.url}
              status={item.status}
              enableEdit
            />
          ))}
      </Stack>
      <Box mt={3}>
        <Button
          size="small"
          icon={<PlusOutlined />}
          onClick={() =>
            onUploadDocument({
              type: 'manufacturing-document',
              shipmentId: shipment._id,
            })
          }
        >
          Add Document
        </Button>
      </Box>
      <Stack align="center" gap={4} mt={4}>
        <FileOutlined />
        <Typo fontWeight={600}>Shipping Documents</Typo>
      </Stack>
      <Stack vertical gap={12} mt={3}>
        {trade.documents
          .filter(
            (item) =>
              item.shipmentId === shipment._id &&
              item.type === 'importing-document',
          )
          .map((item, index) => (
            <DocumentItem
              key={`importing-${index}`}
              name={item.name}
              path={item.url}
              status={item.status}
              enableEdit
            />
          ))}
      </Stack>
      <Box mt={3}>
        <Button
          size="small"
          icon={<PlusOutlined />}
          onClick={() =>
            onUploadDocument({
              type: 'importing-document',
              shipmentId: shipment._id,
            })
          }
        >
          Add Document
        </Button>
      </Box>
      <Stack align="center" gap={4} mt={4} mb={2}>
        <DollarCircleOutlined />
        <Typo fontWeight={600}>Payments/Transactions</Typo>
      </Stack>
      <Table
        dataSource={shipment.payments}
        columns={[
          {
            dataIndex: 'name',
            title: 'Name',
          },
          {
            dataIndex: 'total',
            title: 'Amount',
            render: (value) => `$${Number(value).toLocaleString()}`,
          },
          {
            dataIndex: 'stage',
            title: 'Stage',
            render: (value) =>
              value === 'after-manufacturing'
                ? 'After Manufacturing'
                : value === 'after-shipping'
                  ? 'After Shipping'
                  : value === 'after-delivering'
                    ? 'After Delivering'
                    : '',
          },
          {
            dataIndex: 'status',
            title: 'Status',
            render: (value) => <PaymentStatusTag status={value} />,
          },
          {
            dataIndex: '_id',
            title: '',
            render: (value, row) => (
              <PaymentActionMenu
                shipment={shipment}
                payment={row}
                trade={trade}
              />
            ),
          },
        ]}
        rowKey={(item) => item._id}
        size="small"
        pagination={{
          pageSize: 100,
          position: ['none', 'none'],
        }}
      />
    </Card>
  );
}

TradeShipmentStageCard.propTypes = {
  title: PropTypes.string,
  shipment: PropTypes.any,
  trade: PropTypes.any,
  onUploadDocument: PropTypes.func,
};

function PaymentActionMenu({ shipment, payment, trade }) {
  const navigate = useNavigate();

  const handleViewInvoice = () => {
    for (const row of trade.documents) {
      if (
        row.shipmentId === shipment._id &&
        row.paymentId === payment._id &&
        row.type === 'invoice'
      ) {
        window.open(getStoragePath(row.url), '_blank');
        return;
      }
    }
  };

  const handleViewReceipt = () => {
    for (const row of trade.documents) {
      if (
        row.shipmentId === shipment._id &&
        row.paymentId === payment._id &&
        row.type === 'receipt'
      ) {
        window.open(getStoragePath(row.url), '_blank');
        return;
      }
    }
  };

  const handleViewTransaction = () => {
    if (payment.transaction) {
      navigate(`/transactions/${payment.transaction}`);
    }
  };

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: [
          {
            label: 'View Invoice',
            onClick: handleViewInvoice,
          },
          {
            label: 'View Receipt',
            onClick: handleViewReceipt,
          },
          {
            label: 'View Transaction',
            onClick: handleViewTransaction,
          },
        ],
      }}
    >
      <Button icon={<MoreOutlined />} size="small" />
    </Dropdown>
  );
}

PaymentActionMenu.propTypes = {
  shipment: PropTypes.any,
  payment: PropTypes.any,
  trade: PropTypes.any,
};
