import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Modal, Select } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import { Box, Typo } from '../../../components/ui';

export default function ProductSwitchStatusModal({
  open,
  initialValues,
  processing,
  onSubmit,
  onCancel,
}) {
  const [form] = Form.useForm();

  const handleSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  useEffect(() => {
    form.setFieldsValue({
      id: initialValues?.id ? initialValues.id : '',
      status: initialValues?.status ? initialValues.status : '',
    });
  }, [initialValues]);

  return (
    <Modal
      title="Change Status"
      open={open}
      okText="Submit"
      okButtonProps={{
        loading: processing,
        icon: <CheckOutlined />,
      }}
      onOk={handleSubmit}
      onCancel={onCancel}
    >
      <Typo>
        Select from the available status for the product.
        <br />
        Out of Stock products , will be visible to user but unable to request an
        order, Disabled produces will be hidden from users view.
      </Typo>
      <Box mt={4}>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item name="id" hidden>
            <Input hidden />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            required
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Select placeholder="Select status">
              <Select.Option value="Pending">Pending</Select.Option>
              <Select.Option value="Draft">Draft</Select.Option>
              <Select.Option value="Published">Published</Select.Option>
              <Select.Option value="Out of Stock">Out of Stock</Select.Option>
              <Select.Option value="Disabled">Disabled</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Box>
    </Modal>
  );
}

ProductSwitchStatusModal.propTypes = {
  open: PropTypes.bool,
  initialValues: PropTypes.any,
  processing: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};
