import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PageBreadcrumbs from './page-breadcrumbs.component';
import PageHeader from './page-header.component';
import PageTransactionsView from './page-transactions-view.component';

import { apiGetTransactions } from '../../../apis/transactions';
import CompanyDetailsView from '../../../views/company/company.view';

export default function TransactionManagementPage() {
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const pageIndex = params.get('pageIndex')
    ? parseInt(params.get('pageIndex'))
    : 1;
  const pageSize = params.get('pageSize')
    ? parseInt(params.get('pageSize'))
    : 10;
  const status = params.get('status') ? params.get('status') : '';
  const sorter = params.get('sorter') ? params.get('sorter') : '';
  const [sortBy, sortOrder] = sorter.split(',');

  const { data, isLoading } = useQuery(
    ['transactions', pageIndex, pageSize, status, sortBy, sortOrder],
    async () => {
      return await apiGetTransactions({
        pageIndex,
        pageSize,
        ...(status && { status }),
        ...(sortBy && { sortBy }),
        ...(sortBy &&
          (sortOrder === 'ascend' || sortOrder === 'descend') && {
            sortBy,
            sortOrder: sortOrder === 'ascend' ? 'asc' : 'desc',
          }),
      });
    },
  );

  const [companyView, setCompanyView] = useState({
    id: null,
    open: false,
  });

  const handleViewBusiness = (id) => {
    setCompanyView({
      id: id,
      open: true,
    });
  };

  const handleViewTrade = (id) => {
    navigate(`/trades/${id}`);
  };

  const handleViewDetails = (id) => {
    navigate(`/transactions/${id}`);
  };

  const handleCloseCompanyView = () => {
    setCompanyView((values) => ({
      ...values,
      open: false,
    }));
  };

  return (
    <>
      <PageBreadcrumbs />
      <PageHeader />
      <PageTransactionsView
        data={data?.data ?? []}
        isLoading={isLoading}
        onViewSeller={handleViewBusiness}
        onViewBuyer={handleViewBusiness}
        onViewTrade={handleViewTrade}
        onViewDetails={handleViewDetails}
      />
      <CompanyDetailsView
        open={companyView.open}
        id={companyView.id}
        onClose={handleCloseCompanyView}
      />
    </>
  );
}
