import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Image, Space, Table, Tag } from 'antd';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import querystring from 'query-string';

import { Box, Typo } from '../../../components/ui';
import ActionMenu from './action-menu.component';
import TextFilterDropdown from '../../../components/mixed/TextFilterDropdown';

import constants from '../../../config/constants';
import { apiGetCategories } from '../../../apis/categories';
import { apiGetCompanies } from '../../../apis/companies';
import { getStoragePath } from '../../../apis/utilities';

export default function PageProductsView({
  isLoading,
  data,
  onApprove,
  onEdit,
  onChangeStatus,
  onArchive,
  onViewSeller,
}) {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { data: categories } = useQuery(['categories'], async () => {
    const result = await apiGetCategories();
    if (result) {
      return result;
    }
    return [];
  });
  const { data: companies } = useQuery(['companies'], async () => {
    const result = await apiGetCompanies();
    if (result) return result;
    return [];
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});

  const handleChangeTable = (pagination, filters, sorter) => {
    const params = {
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      ...filters,
      sorter:
        sorter?.order === 'ascend' || sorter?.order === 'descend'
          ? [sorter.field, sorter.order]
          : null,
    };
    const query = querystring.stringify(params, {
      arrayFormat: 'separator',
      skipNull: true,
    });
    navigate(`/products?${query}`);
  };

  useEffect(() => {
    const queries = querystring.parse(params.toString(), {
      arrayFormat: 'separator',
    });
    setPagination({
      ...pagination,
      current: queries.current ? +queries.current : 1,
      pageSize: queries.pageSize ? +queries.pageSize : 10,
    });
    setFilters({
      name: queries.name ? [queries.name] : null,
      company: queries.company
        ? typeof queries.company === 'string'
          ? [queries.company]
          : queries.company
        : null,
      category: queries.category
        ? typeof queries.category === 'string'
          ? [queries.category]
          : queries.category
        : null,
      status: queries.status
        ? typeof queries.status === 'string'
          ? [queries.status]
          : queries.status
        : null,
    });
    setSorter(
      queries.sorter?.length === 2
        ? {
            field: queries.sorter[0],
            order: queries.sorter[1],
          }
        : {},
    );
  }, [params]);

  return (
    <Box mt={4}>
      <Table
        dataSource={data}
        loading={isLoading}
        columns={[
          {
            dataIndex: '_id',
            title: 'Product ID',
            render: (value) => (
              <NavLink to={`/products/${value}`}>
                {'#' + value.substr(-6).toUpperCase()}
              </NavLink>
            ),
          },
          {
            dataIndex: 'name',
            title: 'Product Name',
            render: (value, row) => (
              <Space size={8}>
                <Image.PreviewGroup
                  items={row.photos.map(
                    (item) => `${constants.STORAGE_HOST}/${item}`,
                  )}
                >
                  <Image
                    width={60}
                    height={40}
                    src={
                      row.photos.length > 0
                        ? `${constants.STORAGE_HOST}/${row.photos[0]}`
                        : ''
                    }
                    style={{
                      objectFit: 'cover',
                    }}
                  />
                </Image.PreviewGroup>
                <Typo>{value}</Typo>
              </Space>
            ),
            filterDropdown: (props) => (
              <TextFilterDropdown
                {...props}
                placeholder="Search product name"
              />
            ),
            onFilter: (value, row) =>
              row.name.toLowerCase().includes(value.toLowerCase()),
            filteredValue: filters.name,
          },
          {
            dataIndex: 'company',
            title: 'Seller',
            render: (value) => (
              <Space size={8}>
                <Avatar
                  src={getStoragePath(value?.logo)}
                  size={40}
                  icon={<UserOutlined />}
                />
                <Typo>{value?.name ? value.name : <Tag>Seller</Tag>}</Typo>
              </Space>
            ),
            filters: companies?.map((item) => ({
              text: item.name,
              value: item._id,
            })),
            filterSearch: true,
            onFilter: (value, row) => {
              return row.company?._id === value;
            },
            filteredValue: filters.company,
          },
          {
            dataIndex: 'category',
            title: 'Category',
            render: (value) => value?.name,
            filters: categories?.map((item) => ({
              text: item.name,
              value: item._id,
            })),
            filterSearch: true,
            onFilter: (value, row) => row.category?._id === value,
            filteredValue: filters.category,
          },
          {
            dataIndex: 'price',
            title: 'Price',
            render: (value) => '$' + Number(value).toFixed(2),
            sorter: (a, b) => (a.price > b.price ? 1 : -1),
            sortOrder: sorter.order,
          },
          {
            dataIndex: 'status',
            title: 'Status',
            render: (value) => (
              <Tag color={getStatusTagColor(value)}>{value}</Tag>
            ),
            filters: [
              { text: 'Pending', value: 'Pending' },
              { text: 'Draft', value: 'Draft' },
              { text: 'Published', value: 'Published' },
              { text: 'Out of Stock', value: 'Out of Stock' },
              { text: 'Disabled', value: 'Disabled' },
            ],
            onFilter: (value, row) => row.status === value,
            filteredValue: filters.status,
          },
          {
            dataIndex: '_id',
            title: '',
            render: (value, row) => (
              <ActionMenu
                disabledApprove={row.status === 'Published'}
                onApprove={() => onApprove(value)}
                onEdit={() => onEdit(value)}
                onChangeStatus={() => onChangeStatus(row)}
                onArchive={() => onArchive(row._id)}
                onOpenSeller={() => onViewSeller(row.company?._id)}
              />
            ),
          },
        ]}
        rowKey={(item) => `row-${item._id}`}
        pagination={pagination}
        onChange={handleChangeTable}
      />
    </Box>
  );
}

PageProductsView.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  onApprove: PropTypes.func,
  onEdit: PropTypes.func,
  onChangeStatus: PropTypes.func,
  onArchive: PropTypes.func,
  onViewSeller: PropTypes.func,
};

function getStatusTagColor(status) {
  switch (status) {
    case 'Draft':
      return 'purple';
    case 'Pending':
      return 'blue';
    case 'Published':
      return 'green';
    case 'Out of Stock':
      return 'orange';
    case 'Disabled':
      return 'red';
    default:
      return undefined;
  }
}
