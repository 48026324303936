import React, { useState } from 'react';
import { Dropdown, Space, Spin } from 'antd';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Stack } from '../../../components/ui';
import PageBreadcrumbs from './page-breadcrumbs.component';
import PageHeader from './page-header.component';
import PageTransactionSummaryCard from './page-transaction-summary.component';
import PageItemsCard from './page-items.component';

import {
  apiGetTransactionDetails,
  apiPatchTransaction,
} from '../../../apis/transactions';
import { getStoragePath } from '../../../apis/utilities';
import EditTransactionModal from './edit-transaction.modal';

export default function EditTransactionPage() {
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id;

  const {
    data: transaction,
    isLoading,
    refetch,
  } = useQuery(['transactions', id], async () => {
    return await apiGetTransactionDetails(id);
  });

  const [processing, setProcessing] = useState(false);
  const [editing, setEditing] = useState(false);

  const handleViewInvoice = () => {
    window.open(getStoragePath(transaction?.files?.invoice), '_blank');
  };

  const handleViewReceipt = () => {
    window.open(getStoragePath(transaction?.files?.receipt), '_blank');
  };

  const handleViewTrade = () => {
    if (transaction?.trade?._id) {
      navigate(`/trades/${transaction.trade._id}`);
    }
  };

  const handleUpdate = async (values) => {
    setEditing(false);
    setProcessing(true);
    await apiPatchTransaction({
      id: id,
      ...values,
    });
    await refetch();
    setProcessing(false);
  };

  return (
    <>
      <Stack justify="space-between" align="center">
        <Box>
          <PageBreadcrumbs />
          <PageHeader />
        </Box>
        <Space>
          <Dropdown.Button
            type="primary"
            loading={processing}
            menu={{
              items: [
                {
                  key: 'key-view-invoice',
                  label: 'View Invoice',
                  onClick: handleViewInvoice,
                },
                {
                  key: 'key-view-receipt',
                  label: 'View Receipt',
                  onClick: handleViewReceipt,
                },
                {
                  key: 'key-view-trade',
                  label: 'View Trade',
                  onClick: handleViewTrade,
                },
              ],
            }}
            onClick={() => setEditing(true)}
          >
            Edit Transaction
          </Dropdown.Button>
        </Space>
      </Stack>
      <Spin spinning={isLoading}>
        <div>
          <PageTransactionSummaryCard transaction={transaction} />
          <PageItemsCard transaction={transaction} />
        </div>
      </Spin>
      <EditTransactionModal
        open={editing}
        transaction={transaction}
        onClose={() => setEditing(false)}
        onSubmit={handleUpdate}
      />
    </>
  );
}
