import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Dropdown, Table } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import PaymentStatusTag from '../../../../components/tags/PaymentStatusTag';
import { getStoragePath } from '../../../../apis/utilities';

export default function TradeInitialPaymentsCard({ trade }) {
  return (
    <Card
      title="Initial Payments"
      style={{
        marginBottom: 16,
        display: trade?.initialPayments?.length > 0 ? 'block' : 'none',
      }}
    >
      <Table
        dataSource={trade?.initialPayments ?? []}
        columns={[
          {
            dataIndex: 'name',
            title: 'Name',
          },
          {
            dataIndex: 'total',
            title: 'Amount',
            render: (value) => `$${Number(value).toLocaleString()}`,
          },
          {
            dataIndex: 'status',
            title: 'Status',
            render: (value) => <PaymentStatusTag status={value} />,
          },
          {
            dataIndex: '_id',
            title: '',
            render: (value, row) => (
              <PaymentActionMenu payment={row} trade={trade} />
            ),
          },
        ]}
        rowKey={(item) => item._id}
        size="small"
        pagination={{
          pageSize: 100,
          position: ['none', 'none'],
        }}
      />
    </Card>
  );
}

TradeInitialPaymentsCard.propTypes = {
  trade: PropTypes.any,
};

function PaymentActionMenu({ payment, trade }) {
  const navigate = useNavigate();

  const handleViewInvoice = () => {
    if (trade && trade.transactions) {
      for (const row of trade.transactions) {
        if (row._id === payment.transaction) {
          window.open(getStoragePath(row.files.invoice), '_blank');
          return;
        }
      }
    }
  };

  const handleViewReceipt = () => {
    for (const row of trade.documents) {
      if (row.paymentId === payment._id && row.type === 'receipt') {
        window.open(getStoragePath(row.url), '_blank');
        return;
      }
    }
  };

  const handleViewTransaction = () => {
    if (payment.transaction) {
      navigate(`/transactions/${payment.transaction}`);
    }
  };

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: [
          {
            label: 'View Invoice',
            onClick: handleViewInvoice,
          },
          {
            label: 'View Receipt',
            onClick: handleViewReceipt,
          },
          {
            label: 'View Transaction',
            onClick: handleViewTransaction,
          },
        ],
      }}
    >
      <Button icon={<MoreOutlined />} size="small" />
    </Dropdown>
  );
}

PaymentActionMenu.propTypes = {
  payment: PropTypes.any,
  trade: PropTypes.any,
};
