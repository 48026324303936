import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setAuth: (state, action) => {
      state = action.payload;
      return state;
    },
    updateAuth: (state, action) => {
      state = {
        ...state,
        ...action.payload,
      };
      return state;
    },
    resetAuth: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { setAuth, updateAuth, resetAuth } = authSlice.actions;

export default authSlice;
