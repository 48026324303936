import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';

import AppRouter from './routers/app.router';

import { default as antTheme } from './config/antd.theme';
import { default as styledTheme } from './config/styled.theme';
import store from './redux/store';
import { persistor } from './redux/store';

const queryClient = new QueryClient();

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyleProvider hashPriority="high">
          <ConfigProvider theme={antTheme}>
            <ThemeProvider theme={styledTheme}>
              <QueryClientProvider client={queryClient}>
                <AppRouter />
              </QueryClientProvider>
            </ThemeProvider>
          </ConfigProvider>
        </StyleProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
