import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Space, Table, Tag } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import querystring from 'query-string';

import { Box, Typo } from '../../components/ui';
import TextFilterDropdown from '../../components/mixed/TextFilterDropdown';
import ActionMenu from './action-menu.component';
import { getStoragePath } from '../../apis/utilities';

export default function PageCompaniesView({ data, isLoading, onEdit }) {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});

  const handleChangeTable = (pagination, filters, sorter) => {
    const params = {
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      ...filters,
      sorter:
        sorter?.order === 'ascend' || sorter?.order === 'descend'
          ? [sorter.field, sorter.order]
          : null,
    };
    const query = querystring.stringify(params, {
      arrayFormat: 'separator',
      skipNull: true,
    });
    navigate(`/businesses?${query}`);
  };

  useEffect(() => {
    const queries = querystring.parse(params.toString(), {
      arrayFormat: 'separator',
    });
    setPagination({
      ...pagination,
      current: queries.pageIndex ? +queries.pageIndex : 1,
      pageSize: queries.pageSize ? +queries.pageSize : 10,
    });
    setFilters({
      name: queries.name ? [queries.name] : null,
      email: queries.email ? [queries.email] : null,
    });
    setSorter(
      queries.sorter?.length === 2
        ? {
            field: queries.sorter[0],
            order: queries.sorter[1],
          }
        : {},
    );
  }, [params]);

  return (
    <Box mt={4}>
      <Table
        dataSource={data}
        columns={[
          {
            dataIndex: '_id',
            title: 'ID',
            render: (value) => (
              <Button type="link" onClick={() => onEdit(value)}>
                #{value.substr(-6).toUpperCase()}
              </Button>
            ),
            sorter: (a, b) => (a?._id > b?._id ? 1 : -1),
            sortOrder: sorter.field === '_id' ? sorter.order : null,
          },
          {
            dataIndex: 'name',
            title: 'Business',
            render: (value, row) => (
              <Space>
                <Avatar src={getStoragePath(row.logo)} />
                <Typo>{value}</Typo>
              </Space>
            ),
            sorter: (a, b) => (a.name > b.name ? 1 : -1),
            sortOrder: sorter.field === 'name' ? sorter.order : null,
            filterDropdown: (props) => (
              <TextFilterDropdown {...props} placeholder="Search name" />
            ),
            onFilter: (value, record) =>
              record.name.toLowerCase().includes(value.toLowerCase()),
            filteredValue: filters.name,
          },
          {
            dataIndex: 'email',
            title: 'Email',
            sorter: (a, b) => (a.email > b.email ? 1 : -1),
            sortOrder: sorter.field === 'email' ? sorter.order : null,
            filterDropdown: (props) => (
              <TextFilterDropdown {...props} placeholder="Search email" />
            ),
            onFilter: (value, record) =>
              record.email
                ? record.email.toLowerCase().includes(value.toLowerCase())
                : false,
            filteredValue: filters.email,
          },
          {
            dataIndex: 'isVerified',
            title: 'KYB',
            render: (value) =>
              value ? (
                <Tag color="green">Verified</Tag>
              ) : (
                <Tag color="red">In Review</Tag>
              ),
          },
          {
            dataIndex: '_id',
            title: '',
            render: (value) => <ActionMenu id={value} onEdit={onEdit} />,
          },
        ]}
        rowKey={(row) => `business-${row._id}`}
        loading={isLoading}
        pagination={pagination}
        onChange={handleChangeTable}
      />
    </Box>
  );
}

PageCompaniesView.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  onEdit: PropTypes.func,
};
