import styled from 'styled-components';
import {
  space,
  layout,
  typography,
  color,
  border,
  flex,
  background,
  borders,
} from 'styled-system';

const Box = styled.div`
  ${space}
  ${layout}
  ${typography}
  ${color}
  ${border}
  ${flex}
  ${background}
  ${borders}
`;

export default Box;
