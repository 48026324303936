import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, Space, Table } from 'antd';
import dayjs from 'dayjs';

import { Box, Typo } from '../../../components/ui';
import DocumentStatusTag from '../../../components/tags/DocumentStatusTag';
import {
  getFileTypeFromUrl,
  getFilenameFromUrl,
  getStoragePath,
} from '../../../apis/utilities';

export default function PageDocumentsTab({ trade }) {
  return (
    <Box>
      <Card
        title="Trade Requirements"
        style={{
          marginBottom: 16,
        }}
      >
        <DocumentsTable trade={trade} type="trade-requirement" />
      </Card>
      <Card
        title="Sales Purchase Agreement"
        style={{
          marginBottom: 16,
        }}
      >
        <DocumentsTable trade={trade} type="sales-agreement" />
      </Card>
      <Card
        title="Proof of Product"
        style={{
          marginBottom: 16,
        }}
      >
        <DocumentsTable trade={trade} type="product-proof" />
      </Card>
      <Card
        title="Manufacturing Documents"
        style={{
          marginBottom: 16,
        }}
      >
        <DocumentsTable trade={trade} type="manufacturing-document" />
      </Card>
      <Card
        title="Shipping Documents"
        style={{
          marginBottom: 16,
        }}
      >
        <DocumentsTable trade={trade} type="importing-document" />
      </Card>
      <Card
        title="Invoices"
        style={{
          marginBottom: 16,
        }}
      >
        <DocumentsTable trade={trade} type="invoice" />
      </Card>
      <Card
        title="Payment Receipts"
        style={{
          marginBottom: 16,
        }}
      >
        <DocumentsTable trade={trade} type="receipt" />
      </Card>
    </Box>
  );
}

PageDocumentsTab.propTypes = {
  trade: PropTypes.any,
};

function DocumentsTable({ trade, type }) {
  const rows = useMemo(() => {
    return trade.documents.filter((item) => item.type === type);
  }, [trade, type]);

  return (
    <Table
      dataSource={rows}
      columns={[
        {
          dataIndex: 'any',
          title: 'Type',
          render: (value, row) => getFileTypeFromUrl(row.url).toUpperCase(),
        },
        {
          dataIndex: 'name',
          title: 'Document',
          render: (value, row) => (
            <Space direction="vertical" size={0}>
              <Typo type="secondary">{row.name}</Typo>
              <a
                href={getStoragePath(row.url)}
                target="_blank"
                rel="noreferrer"
              >
                {getFilenameFromUrl(row.url)}
              </a>
            </Space>
          ),
        },
        {
          dataIndex: 'version',
          title: 'Version',
          render: (value) => value.toString().padStart(2, '0'),
        },
        {
          dataIndex: 'uploadedAt',
          title: 'Uploaded At',
          render: (value) => dayjs(value).format('DD MMM, YYYY'),
        },
        {
          dataIndex: 'status',
          title: 'Status',
          render: (value) => <DocumentStatusTag status={value} />,
        },
      ]}
      size="small"
      pagination={{
        position: ['none', 'none'],
      }}
      rowKey={(item) => item._id}
    />
  );
}

DocumentsTable.propTypes = {
  trade: PropTypes.any,
  type: PropTypes.string,
};
