import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'antd';
import { EyeOutlined, MoreOutlined } from '@ant-design/icons';

export default function ActionMenu({
  id,
  onViewSeller,
  onViewBuyer,
  onViewTrade,
  onViewTransaction,
}) {
  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              label: 'View Seller',
              icon: <EyeOutlined />,
              onClick: () => onViewSeller(id),
            },
            {
              label: 'View Buyer',
              icon: <EyeOutlined />,
              onClick: () => onViewBuyer(id),
            },
            {
              label: 'View Trade',
              icon: <EyeOutlined />,
              onClick: () => onViewTrade(id),
            },
            {
              label: 'View Transaction',
              icon: <EyeOutlined />,
              onClick: () => onViewTransaction(id),
            },
          ],
        }}
        trigger={['click']}
      >
        <Button icon={<MoreOutlined />} size="small" />
      </Dropdown>
    </>
  );
}

ActionMenu.propTypes = {
  id: PropTypes.any,
  onViewSeller: PropTypes.func,
  onViewBuyer: PropTypes.func,
  onViewTrade: PropTypes.func,
  onViewTransaction: PropTypes.func,
};
