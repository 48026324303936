import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import AuthGuard from '../components/auth.guard';
import AuthLayout from '../components/layouts/auth-layout';
import LoginPage from '../pages/auth/login/login.page';
import LogoutPage from '../pages/auth/logout/logout.page';

import MainLayout from '../components/layouts/main-layout';
import DashboardPage from '../pages/dashboard/dashboard.page';
import UserManagementPage from '../pages/users/users.page';
import BusinessManagementPage from '../pages/companies/companies.page';
import CategoryManagementPage from '../pages/categories/categories.page';
import ProductManagementPage from '../pages/products/index/products.page';
import EditProductPage from '../pages/products/edit/edit-product.page';
import TradeManagementPage from '../pages/trades/index/trades.page';
import EditTradePage from '../pages/trades/edit/edit-trade.page';
import TransactionManagementPage from '../pages/transactions/index/transactions.page';
import EditTransactionPage from '../pages/transactions/edit/edit-transaction.page';
import Error404Page from '../pages/errors/404.page';

export default function AppRouter() {
  return (
    <React.Suspense fallback={<></>}>
      <BrowserRouter future={{ v7_startTransition: true }}>
        <Routes>
          <Route path="/auth" element={<AuthLayout />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="logout" element={<LogoutPage />} />
          </Route>
          <Route
            path="/"
            element={
              <AuthGuard>
                <MainLayout />
              </AuthGuard>
            }
          >
            <Route path="" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/users" element={<UserManagementPage />} />
            <Route path="/businesses" element={<BusinessManagementPage />} />
            <Route path="/categories" element={<CategoryManagementPage />} />
            <Route path="/products" element={<ProductManagementPage />} />
            <Route path="/products/:id" element={<EditProductPage />} />
            <Route path="/trades" element={<TradeManagementPage />} />
            <Route path="/trades/:id" element={<EditTradePage />} />
            <Route
              path="/transactions"
              element={<TransactionManagementPage />}
            />
            <Route path="/transactions/:id" element={<EditTransactionPage />} />
            <Route path="*" element={<Error404Page />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.Suspense>
  );
}
