import axios from 'axios';
import store from '../redux/store';

import constants from '../config/constants';

axios.defaults.baseURL = constants.API_HOST;
axios.interceptors.request.use((config) => {
  const token = store.getState().auth?.accessToken;
  if (token) {
    config.headers['X-ACCESS-TOKEN'] = token;
  }
  return config;
});
