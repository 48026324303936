import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import PageHeader from './page-header.component';
import PageBreadcrumbs from './page-breadcrumbs.component';
import PageCategoriesView from './page-categories-view.component';
import EditCategoryModal from './edit-category.modal';

import {
  apiCreateCategory,
  apiDeleteCategory,
  apiGetCategories,
  apiUpdateCategory,
} from '../../apis/categories';

export default function CategoryManagementPage() {
  const { data, isLoading, refetch } = useQuery(['categories'], async () => {
    return await apiGetCategories();
  });
  const [processing, setProcessing] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpenEditor = useCallback(
    (row) => {
      setSelectedRow(row);
    },
    [setSelectedRow],
  );

  const handleSubmitCategory = useCallback(async (values) => {
    values = {
      ...values,
      parent: values.parent ? values.parent : 'Root',
      image: values.image[0]?.response?.filename ?? null,
    };
    if (!values.image) {
      delete values.image;
    }
    setProcessing(true);
    if (values.id) {
      await apiUpdateCategory(values.id, values);
    } else {
      await apiCreateCategory(values);
    }
    await refetch();
    setProcessing(false);
    setSelectedRow(null);
  }, []);

  const handleToggleStatus = useCallback(async (id, checked) => {
    setProcessing(true);
    await apiUpdateCategory(id, {
      status: checked ? 'active' : 'inactive',
    });
    await refetch();
    setProcessing(false);
  }, []);

  const handleDeleteCategory = useCallback(
    async (id) => {
      setProcessing(true);
      await apiDeleteCategory(id);
      await refetch();
      setProcessing(false);
    },
    [setProcessing],
  );

  return (
    <>
      <PageBreadcrumbs />
      <PageHeader onAdd={() => handleOpenEditor({})} />
      <PageCategoriesView
        data={data ? data : []}
        isLoading={isLoading || processing}
        onEdit={handleOpenEditor}
        onDelete={handleDeleteCategory}
        onToggleStatus={handleToggleStatus}
      />
      <EditCategoryModal
        initialValues={selectedRow}
        open={!!selectedRow}
        loading={processing}
        onSubmit={handleSubmitCategory}
        onCancel={() => setSelectedRow(null)}
        categories={data ? data : []}
      />
    </>
  );
}
