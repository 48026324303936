import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

import { Box } from '../../../components/ui';

const PageBreadcrumbs = ({ id }) => {
  return (
    <Box mb={0}>
      <Breadcrumb
        items={[
          {
            href: '/',
            title: <HomeOutlined />,
          },
          {
            href: '/products',
            title: 'Products',
          },
          {
            href: '#',
            title: id === 'new' ? 'Add new product' : 'Edit product',
          },
        ]}
        itemRender={(route) => <NavLink to={route.href}>{route.title}</NavLink>}
      />
    </Box>
  );
};

PageBreadcrumbs.propTypes = {
  id: PropTypes.any,
};

export default PageBreadcrumbs;
