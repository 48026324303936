import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'antd';
import {
  CheckOutlined,
  EditOutlined,
  FileZipOutlined,
  LinkOutlined,
  MoreOutlined,
  SwapOutlined,
} from '@ant-design/icons';

export default function ActionMenu({
  disabledApprove,
  onApprove,
  onEdit,
  onChangeStatus,
  onArchive,
  onOpenSeller,
}) {
  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: [
          {
            label: 'Approve',
            icon: <CheckOutlined />,
            onClick: onApprove,
            disabled: disabledApprove,
          },
          {
            label: 'Edit',
            icon: <EditOutlined />,
            onClick: onEdit,
          },
          {
            label: 'Change Status',
            icon: <SwapOutlined />,
            onClick: onChangeStatus,
          },
          {
            label: 'Archive',
            icon: <FileZipOutlined />,
            onClick: onArchive,
          },
          {
            label: 'Go to Seller',
            icon: <LinkOutlined />,
            onClick: onOpenSeller,
          },
        ],
      }}
    >
      <Button icon={<MoreOutlined />} size="small" />
    </Dropdown>
  );
}

ActionMenu.propTypes = {
  onApprove: PropTypes.func,
  onEdit: PropTypes.func,
  onChangeStatus: PropTypes.func,
  onArchive: PropTypes.func,
  onOpenSeller: PropTypes.func,
  disabledApprove: PropTypes.bool,
};
